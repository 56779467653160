import envVars from "@/config/env";
import { isEmpty } from "lodash";
import { ApiError } from "./error";
import qs from "qs";

type IRequest = {
  url: string;
  method: string;
  data?: any;
  params?: any;
  headers?: any;
};

async function executeRequest<T>({
  url,
  method,
  data,
  params,
  headers,
}: IRequest): Promise<T> {
  let baseUrl = envVars.appConfig.apiUrl + url;

  baseUrl += params
    ? `?${qs.stringify(params, {
        encode: false,
        arrayFormat: "brackets",
      })}`
    : "";

  const response = await fetch(baseUrl, {
    method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
    credentials: "include",
  });

  let result = null;
  const textResponse = await response.text();

  if (!isEmpty(textResponse)) {
    result = JSON.parse(textResponse);
  }

  if (!response.ok) {
    throw new ApiError({
      message: result?.message,
      statusCode: response.status,
      data: result.data,
    });
  }

  return result.data;
}

export default class instance {
  static get = <T>(url: string, params?: any) =>
    executeRequest<T>({ url, method: "GET", params });
  static post = <T>(url: string, data?: any) =>
    executeRequest<T>({ url, method: "POST", data });
  static patch = <T>(url: string, data?: any) =>
    executeRequest<T>({ url, method: "PATCH", data });
}
