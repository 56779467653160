import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import { memo } from "react";
import { BaseItemType } from "@/types/ItemType";
import { getAssetUrl } from "../../../utils/assets.utils";
import { t } from "i18next";

interface CardViewProps {
  card?: BaseItemType | null;
  isEnabled: boolean;
  name: string;
}

const CardView = ({ card, name, isEnabled }: CardViewProps) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          cursor: "pointer",
        }}
      >
        <Tooltip
          title={card && <Typography variant="subtitle1">{name}</Typography>}
          placement="top"
          sx={{ borderRadius: "6px", p: "3px 15px" }}
          disableInteractive
          TransitionComponent={Zoom}
        >
          <img
            src={
              isEnabled
                ? card !== null
                  ? getAssetUrl(t("assets.card_slot"))
                  : getAssetUrl(t("assets.empty_card_slot"))
                : getAssetUrl(t("assets.disabled_card_slot"))
            }
          />
        </Tooltip>
      </Box>
    </>
  );
};

export default memo(CardView);
