import { AccountData } from "@/api/account";
import { useQueryParams } from "@/hooks/useQueryParams";
import { useEffect } from "react";

export const useAccountView = (account?: AccountData) => {
  const { getQueryParam, setQueryParam } = useQueryParams();
  const selectedAccount = getQueryParam<string>("accountId");

  const isSelected =
    selectedAccount && account?.accountId === Number(selectedAccount);

  useEffect(() => {
    if (!selectedAccount && account) {
      setQueryParam({ accountId: account?.accountId?.toString() || "" });
    }
  }, [account, selectedAccount, setQueryParam]);

  const handleSelectChar = () => {
    if (!account) return;
    setQueryParam({ accountId: account?.accountId?.toString() || "" });
  };

  return { isSelected, handleSelectChar };
};
