import { StyledScrollable } from "@/pages/GameSettings/GameSettings.styles";
import { ItemType } from "@/types/ItemType";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import { ItemView } from "@/components/ItemView";
import { isEmpty } from "lodash";

interface ItemViewListProps {
  items: ItemType[];
  isLoading: boolean;
}

const ItemViewList: FC<ItemViewListProps> = ({ items, isLoading }) => {
  const renderView = () => {
    if (isLoading) {
      return Array.from({ length: 6 }).map(() => (
        <Box
          component={Skeleton}
          variant="rounded"
          width={175}
          height={230}
          minHeight={230}
        />
      ));
    }

    if (!isLoading && isEmpty(items)) {
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          borderRadius="8px"
          border="1px solid #e6e8ec"
        >
          <Typography variant="subtitle1">Nenhum item encontrado</Typography>
        </Stack>
      );
    }

    return items.map((item) => <ItemView item={item} />);
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <StyledScrollable
        direction="row"
        gap={2}
        sx={{
          height: 266,
          overflowX: "auto",
          width: "100%",
          paddingBottom: 3,
        }}
      >
        {renderView()}
      </StyledScrollable>
    </Box>
  );
};

export default memo(ItemViewList);
