import { ItemType } from "@/types/ItemType";
import { Stack } from "@mui/material";
import CardView from "./CardView";
import { memo } from "react";

interface CardsProps {
  cards: ItemType["cards"];
  slots: number;
  visible: boolean;
}

const Cards = ({ visible, cards = [], slots }: CardsProps) => {
  return (
    <Stack
      direction="row"
      width="100%"
      height="100%"
      position="relative"
      zIndex={0}
      alignItems="center"
    >
      {visible &&
        cards.map((card, index) => (
          <CardView
            card={card}
            name={card?.description.displayName || "Unknown"}
            isEnabled={index < slots}
            key={card?.id || "card" + index}
          />
        ))}
    </Stack>
  );
};

export default memo(Cards);
