import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, memo } from "react";
import { useGetInventory } from "@/api/inventory";
import { SearchField } from "@/components/SearchField";
import { useQueryParams } from "@/hooks/useQueryParams";
import ItemViewList from "@/components/ItemViewList/ItemViewList";

interface InventoryListProps {
  accountId?: string;
  charId?: string;
}

const InventoryList: FC<InventoryListProps> = ({ accountId, charId }) => {
  const { getQueryParam, setQueryParam } = useQueryParams();
  const searchInventory = getQueryParam<string>("searchInventory");
  const inventoryType = getQueryParam<string>("inventoryType") || "all";
  const { inventory, isLoading } = useGetInventory(accountId, charId, {
    search: searchInventory,
    isEquiped: inventoryType === "equiped" || undefined,
    isFavorite: inventoryType === "favorite" || undefined,
  });

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQueryParam({ searchInventory: event.target.value });
  };

  const handleChangeType = (_: any, value: string) => {
    setQueryParam({ inventoryType: value === "all" ? undefined : value });
  };

  return (
    <Stack gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">Inventário</Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <SearchField value={searchInventory} onChange={handleSearch} />
          <ToggleButtonGroup
            value={inventoryType}
            exclusive
            onChange={handleChangeType}
          >
            <ToggleButton value="all">Todos</ToggleButton>
            <ToggleButton value="equiped">Equipados</ToggleButton>
            <ToggleButton value="favorite">Favoritos</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      <ItemViewList items={inventory.list} isLoading={isLoading} />
    </Stack>
  );
};

export default memo(InventoryList);
