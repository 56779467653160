import { UserData } from "@/api/user";
import CPFValidation from "@/components/CPFValidation/CPFValidation";
import { FindCEP } from "@/components/FindCEP";
import { Field } from "@/components/FormField/FormField.types";
import * as yup from "yup";
import { Schema } from "yup";

const addressFields: { [key: string]: Field } = {
  addressZipcode: {
    columns: 4,
    label: "CEP",
    componentType: "number",
    isZip: true,
    action: <FindCEP />,
  },
  addressLine1: {
    columns: 6,
    label: "Endereço",
    disabled: true,
  },
  addressNumber: {
    columns: 2,
    label: "Número",
  },
  addressLine2: {
    columns: 5,
    label: "Complemento",
  },
  addressNeighborhood: {
    columns: 5,
    label: "Bairro",
    disabled: true,
  },
  addressState: {
    columns: 2,
    label: "Estado",
    disabled: true,
  },
  addressCity: {
    columns: 4,
    label: "Cidade",
    disabled: true,
  },
};

const validationSchema = {
  addressZipcode: yup.string().optional(),
  addressLine2: yup.string().optional(),
  addressLine1: yup.string(),
  addressNumber: yup.string().when("addressZipcode", {
    //@ts-expect-error
    is: (val) => !!val,
    then: (schema: Schema) =>
      schema.required("Número do endereço é obrigatório"),
    otherwise: (schema: Schema) => schema.optional(),
  }),
  addressNeighborhood: yup.string(),
  addressState: yup.string(),
  addressCity: yup.string(),
};

export const useInitializeAddressForm = (userData: UserData) => {
  return {
    fields: addressFields,
    initialValues: {
      addressZipcode: userData?.addressZipcode,
      addressLine1: userData?.addressLine1,
      addressNumber: userData?.addressNumber,
      addressNeighborhood: userData?.addressNeighborhood,
      addressLine2: userData?.addressLine2,
      addressCity: userData?.addressCity,
      addressState: userData?.addressState,
    },
    validationSchema,
  };
};
