import { FC, memo, useEffect, useState } from "react";
import {
  Snackbar as BaseSnackbar,
  Stack,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { SnackbarTypes, SNACKBAR_DURATION } from "./Snackbar.constants";
import { StyledSnackbar } from "./Snackbar.styles";
import { SnackbarContent } from "@/providers/SnackbarProvider/SnackbarProvider";

interface SnackbarProps {
  content: SnackbarContent;
  onClose: () => void;
}

const Snackbar: FC<SnackbarProps> = memo(({ content, onClose }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const startTime = Date.now();

    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const newProgress = Math.max(
        100 - (elapsed / SNACKBAR_DURATION) * 100,
        0
      );
      setProgress(newProgress);

      if (elapsed >= SNACKBAR_DURATION) {
        onClose();
        clearInterval(interval);
      }
    }, 100); // Update every 100ms

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SnackbarType = SnackbarTypes[content.type];

  return (
    <StyledSnackbar
      open={true}
      // onClose={onClose}
      message={
        <Stack direction="column" spacing={1} width="100%">
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%" }}
            padding={2}
            pb={1}
            spacing={2}
          >
            <Box
              component={SnackbarType.Icon}
              sx={{ color: SnackbarType.color, fontSize: 22 }}
            />
            <Stack direction="column">
              <Typography variant="body1" sx={{ color: SnackbarType.color }}>
                {content.title || SnackbarType.title}
              </Typography>
              <Typography variant="subtitle2">{content.message}</Typography>
            </Stack>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              "&.MuiLinearProgress-root": {
                borderRadius: 50,
              },
              "& .MuiLinearProgress-bar": {
                background: SnackbarType.color,
                borderRadius: 50,
              },
            }}
          />
        </Stack>
      }
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{
        maxWidth: 400,
        position: "relative",
        "& .MuiPaper-root": {
          background: SnackbarType.bgColor,
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "0px 16px 20px 0px #0000001c",
        },
      }}
    />
  );
});

export default Snackbar;
