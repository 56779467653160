import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import { Box, Stack } from "@mui/material";
import { useGetUser } from "@/api/user";
import { Snackbar } from "@/components/Snackbar";

export interface SnackbarContent {
  id?: number | string;
  title?: string;
  message: string;
  viewed?: boolean;
  createdAt?: string;
  type: string;
}

interface SnackbarContextType {
  snackbars: SnackbarContent[];
  pushSnackbar: (snackbar: SnackbarContent) => void;
  markAsViewed: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [snackbars, setSnackbars] = useState<SnackbarContent[]>([]);
  const [snackbarNotifications, setSnackbarNotifications] = useState<
    SnackbarContent[]
  >([]);

  const { user } = useGetUser();
  const socket = useRef<WebSocket | null>(null);

  const handleNewSnackbar = useCallback((snackbar: SnackbarContent) => {
    setSnackbars((prevSnackbar) => [...prevSnackbar, snackbar]);
    setSnackbarNotifications((prev) => {
      const updatedNotifications = [snackbar, ...prev];
      return updatedNotifications.length > 3
        ? updatedNotifications.slice(0, 3)
        : updatedNotifications;
    });
  }, []);

  useEffect(() => {
    socket.current = new WebSocket(`ws://localhost:9000`);

    socket.current.onopen = () => {
      if (user?.id) {
        socket.current?.send(
          JSON.stringify({ event: "register-session", data: user.id })
        );
      }
    };

    socket.current.onmessage = (event) => {
      const snackbar = JSON.parse(event.data);
      handleNewSnackbar({ ...snackbar, id: useId() });
    };

    return () => {
      socket.current?.close();
    };
  }, [user?.id, handleNewSnackbar]);

  const handleSnackbarClose = (id: number) => () => {
    setSnackbarNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  const markAsViewed = async () => {
    // Marcar todas as notificações como vistas no backend
    // await fetch('/api/snackbars/markAsViewed', {
    //   method: 'POST',
    // });

    setSnackbars((prevSnackbars) =>
      prevSnackbars.map((snackbar) => ({
        ...snackbar,
        viewed: true,
      }))
    );
  };

  return (
    <SnackbarContext.Provider
      value={{ snackbars, markAsViewed, pushSnackbar: handleNewSnackbar }}
    >
      {children}
      <Box sx={{ position: "fixed", top: 16, right: 16, zIndex: 1400 }}>
        <Stack spacing={2}>
          {snackbarNotifications.map((snackbar) => (
            <Snackbar
              key={snackbar.id}
              content={snackbar}
              onClose={handleSnackbarClose(snackbar.id as number)}
            />
          ))}
        </Stack>
      </Box>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
