import StorefrontIcon from "@mui/icons-material/Storefront";
import { SlClose } from "react-icons/sl";
import { SlUserFollowing } from "react-icons/sl";
import { SlCheck } from "react-icons/sl";

export const SnackbarTypes: { [key: string]: any } = {
  MARKET: {
    title: "Mercado",
    color: "#293963",
    bgColor: "white",
    Icon: StorefrontIcon,
  },
  AUTH: {
    title: "Login",
    color: "#89bc89",
    bgColor: "white",
    Icon: SlUserFollowing,
  },
  ERROR: {
    title: "Ihhh... Problemas",
    color: "#bc2c2c",
    bgColor: "white",
    Icon: SlClose,
  },
  SUCCESS: {
    color: "#89bc89",
    bgColor: "white",
    Icon: SlCheck,
  },
};

export const SNACKBAR_DURATION = 6000; // 6 seconds
