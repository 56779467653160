import { ComponentType } from "./FormField.types";
import { NumberField } from "@/components/NumberField";
import { TextField } from "@/components/TextField";
import DateField from "@/components/DateField/DateField";

export const componentTypeToComponent: Record<ComponentType, any> = {
  //   select: FormikMui.Select, // Dn not change this to FormikMui.AutoSelect, it will break some forms
  text: TextField,
  //   countrySelect: NewCountrySelect,
  date: DateField,
  number: NumberField,
  //   radio: FormikMui.RadioCellGroup,
  //   currency: CurrencyField,
  //   currencyMui: FormikMui.CurrencyField,
  //   autocomplete: FormikMui.Autocomplete,
  //   upload: null,
  //   checkbox: FormikMui.Checkbox,
  //   phoneNumber: FormikMuiPhoneInput,
  //   workingLocationSelect: WorkingLocationSelect,
  //   workingLocationReviewSelect: WorkingLocationReviewSelect,
  //   checkboxCellGroup: FormikMuiCheckboxGroup,
  //   textarea: FormikMui.TextArea,
  //   switch: FormikMui.SwitchCell,
  //   personProfile: null,
  //   directReports: null,
  //   directManager: null,
  //   HRISOptionalFields: null,
  //   variableCompensations: null,
  //   jobTitleField: JobTitleField,
  //   seniorityLevelField: SeniorityLevelField,
  //   ratePolicy: RatePolicySelect,
  //   socCodeAutocomplete: SocCodeField,
  //   riskCodeAutocomplete: RiskClassificationCodeField,
  //   employeeIsCorporateTypeCheckbox: EmployeeIsCorporateTypeCheckbox,
  //   overtimeStatusToggle: OvertimeStatusToggleButton,
};
