import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import instance from "@/config/instance";
import { generatePath } from "react-router-dom";

export interface AccountData {
  accountId: number;
  userid: string;
  userPass: string;
  sex: "M" | "F" | "S";
  email: string;
  groupId: number;
  state: number;
  unbanTime: number;
  expirationTime: number;
  logincount: number;
  lastlogin: string | null;
  lastIp: string;
  birthdate: string | null;
  characterSlots: number;
  pincode: string | null;
  pincodeChange: number;
  vipTime: number;
  oldGroup: number;
  webAuthToken: string | null;
  webAuthTokenEnabled: number;
}

export const ACCOUNT_API = "/accounts/:id";
export const useGetAccounts = (id: string) => {
  const getAccountsRequest = (): Promise<AccountData[]> =>
    instance.get(generatePath(ACCOUNT_API, { id }));
  const { data, isLoading, ...rest } = useQuery<
    AccountData[],
    any,
    AccountData[],
    any
  >({
    queryKey: [ACCOUNT_API],
    queryFn: getAccountsRequest,
    retry: false,
    enabled: !!id,
  });

  return useMemo(
    () => ({
      accounts: data || [],
      isLoading,
      ...rest,
    }),
    [isLoading, rest, data]
  );
};
