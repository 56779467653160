import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import { CharData, useGetChar } from "@/api/char";

interface CharInfoProps {
  accountId?: string;
  charId?: string;
}

const CharInfo: FC<CharInfoProps> = ({ accountId, charId }) => {
  const { char } = useGetChar(accountId, charId);
  const { name, baseLevel, jobLevel, lastMap, lastX, lastY } = char;

  return (
    <Stack gap={3}>
      <Typography variant="body2">Informações do personagem</Typography>
      <Grid container columns={12}>
        <Grid
          item
          xs={2}
          height="100%"
          display="flex"
          justifyContent="space-between"
        >
          <Stack gap={0.5}>
            <Typography variant="body1">Nome</Typography>
            <Typography variant="subtitle1">{name}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={2}
          height="100%"
          display="flex"
          justifyContent="space-between"
        >
          <Stack gap={0.5}>
            <Typography variant="body1">Nível</Typography>
            <Typography variant="subtitle1">
              {baseLevel}/{jobLevel}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={2}
          height="100%"
          display="flex"
          justifyContent="space-between"
        >
          <Stack gap={0.5}>
            <Typography variant="body1">Localização</Typography>
            <Typography variant="subtitle1">
              {lastMap} {lastX}/{lastY}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          height="100%"
          display="flex"
          gap={3}
          justifyContent="flex-end"
        >
          <Button variant="outlined" size="small">
            Resetar aparência
          </Button>
          <Button variant="outlined" size="small">
            Resetar posição
          </Button>
          <Button variant="outlined" size="small">
            Desequipar
          </Button>
          <Button variant="outlined" size="small" color={"error"}>
            Excluir
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(CharInfo);
