import { Button } from "@/components/Button";
import { CellField } from "@/components/CellField";
import { Form } from "@/components/Form";
import { useInitializeCardForm } from "@/pages/Wallet/useCardForm";
import { formatNumberWithThousandAndDecimalSeparator } from "@/utils/numberFormat.utils";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { memo } from "react";

const CreditCard = () => {
  const { fields, initialValues, validationSchema } = useInitializeCardForm({});

  const formBoxes = [
    {
      title: "Adicionar novo cartão de crédito",
      fields,
    },
  ];

  return (
    <>
      <Stack gap={3}>
        <Typography variant="h5">Carteira</Typography>

        <Grid container columns={14}>
          <CellField
            size={6}
            title="Método de pagamento"
            value="Visa **** **** **** 1234"
            action={
              <Stack direction="row" gap={1}>
                <Button variant="outlined" size="small">
                  Remover
                </Button>
                <Button variant="contained" size="small">
                  Alterar
                </Button>
              </Stack>
            }
          />
          <Grid xs="auto" ml={3} mr={3}>
            <Divider orientation="vertical" />
          </Grid>
          <CellField
            size={4}
            title="Saldo disponível"
            value={`R$ ${formatNumberWithThousandAndDecimalSeparator(
              "124140"
            )}`}
            action={
              <Button variant="outlined" size="small">
                Sacar
              </Button>
            }
          />
          <Grid xs="auto" ml={3} mr={3}>
            <Divider orientation="vertical" />
          </Grid>
          <CellField
            size={2}
            title="Saldo pendente"
            value={`R$ ${formatNumberWithThousandAndDecimalSeparator("12440")}`}
          />
        </Grid>
        <Form
          formBoxes={formBoxes}
          spacing={1}
          mt={1.5}
          initialValues={initialValues}
          enableReinitialize
          validateOnBlur
          validateOnChange
          validateOnMount={false}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({ values, isValid }) => {
            return (
              <Stack direction="row" justifyContent="flex-start" gap={2}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    !isValid || (values.addressZipcode && !values.addressLine1)
                  }
                  onClick={() => {}}
                >
                  Salvar
                </Button>
              </Stack>
            );
          }}
        </Form>
      </Stack>
    </>
  );
};

export default memo(CreditCard);
