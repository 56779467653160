import { FC, memo, ReactNode } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";

interface CellFieldProps {
  size?: number;
  title: ReactNode | string;
  subtitle?: string;
  value?: string;
  action?: ReactNode;
}

const CellField: FC<CellFieldProps> = ({
  size = 12,
  title,
  subtitle,
  value,
  action,
}) => {
  return (
    <Grid
      item
      xs={size}
      height="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack gap={0.5}>
        <Stack>
          <Typography variant="body1">{title}</Typography>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </Stack>
        {value && <Typography variant="subtitle1">{value}</Typography>}
      </Stack>
      {action}
    </Grid>
  );
};

export default memo(CellField);
