import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useGetUser } from "@/api/user";
import { formatDateLong } from "@/utils/date.utils";
import { CellField } from "@/components/CellField";

const Notifications = () => {
  const { user } = useGetUser();

  return (
    <Stack direction="column" width="100%">
      <Typography variant="h2" mb={8}>
        Notificações
      </Typography>
      <Stack gap={3}>
        <Typography variant="h5">Email</Typography>
        <Grid container justifyContent="space-between">
          <CellField
            title="Novidades e Promoções"
            value="Ative para receber orfetas e promoções da nossa loja."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Atualizações"
            value="Ative para receber os changelogs de atualizações."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Eventos"
            value="Ative para receber emails com os eventos da rotação atual."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Vendas realizadas"
            value="Ative para receber notificações sempre que um item seu for vendido."
            action={<Switch defaultChecked />}
          />
        </Grid>
      </Stack>
      <Box width="100%" my={6}>
        <Divider />
      </Box>
      <Stack gap={3}>
        <Typography variant="h5">Whatsapp</Typography>
        <Grid container justifyContent="space-between">
          <CellField
            title="Chefes"
            value="Ative para receber notificações 5 minutos antes do nascimento dos Chefes."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Eventos automáticos"
            value="Ative para receber notivicações 5 minutos antes do inicio dos eventos."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Favoritos do mercado"
            value="Ative para receber notificações sempre um item favoritado for anunciado."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Ataque a proteções do castelo"
            value="Ative para receber notificações sempre que as proteções do seu castelo forem atacadas."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Desafio de Castelo"
            value="Ative para receber notificações sempre que seu castelo for desafiado."
            action={<Switch defaultChecked />}
          />
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CellField
            title="Desafio PvP"
            value="Ative para receber notificações sempre que receber um desafio PvP."
            action={<Switch defaultChecked />}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default memo(Notifications);
