import { buttonStyle } from "./components/button";
import { cssBaselineStyle } from "./components/cssBasseline";
import { dividerStyle } from "./components/divider";
import { inputStyle } from "./components/input";
import { toggleButtonStyle } from "./components/toggleButton";
import { tooltipStyle } from "./components/tooltip";

const components = {
  ...cssBaselineStyle,
  ...inputStyle,
  ...buttonStyle,
  ...dividerStyle,
  ...tooltipStyle,
  ...toggleButtonStyle,
};

export default components;
