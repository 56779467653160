import {
  Button as ButtonBase,
  ButtonProps as BaseButtonsProps,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { FC, memo } from "react";

interface ButtonProps extends BaseButtonsProps {
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({
  isLoading,
  disabled,
  children,
  ...props
}) => {
  return (
    <ButtonBase
      disabled={isLoading || disabled}
      {...props}
      sx={{
        position: "relative",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        py={0.5}
        position="absolute"
        visibility={!isLoading ? "hidden" : "visible"}
      >
        <CircularProgress
          size={16}
          sx={{
            height: "100%",
            color: "#141416",
          }}
        />
      </Box>

      <Box visibility={isLoading ? "hidden" : "visible"}>{children}</Box>
    </ButtonBase>
  );
};

export default memo(Button);
