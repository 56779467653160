import { useGetStorage } from "@/api/storage";
import { ItemView } from "@/components/ItemView";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { FC, memo, useEffect } from "react";
import { StyledScrollable } from "../GameSettings.styles";
import { CharView } from "@/components/CharView";
import { useGetChars } from "@/api/char";
import CharInfo from "./CharInfo";
import { useQueryParams } from "@/hooks/useQueryParams";
import InventoryList from "./InventoryList";

interface CharListProps {
  accountId?: string;
}

const MAX_CHARS = 9;

const CharList: FC<CharListProps> = ({ accountId }) => {
  const { chars } = useGetChars(accountId);
  const { getQueryParam, setQueryParam } = useQueryParams();
  const selectedChar = getQueryParam<string>("charId");

  useEffect(() => {
    if (!selectedChar && chars.length > 0) {
      setQueryParam({ charId: chars[0].charId.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chars]);

  return (
    <Stack>
      <Stack>
        <Typography variant="h5">Meus personagens</Typography>
        <Stack gap={3}>
          <Box sx={{ display: "flex", overflow: "hidden" }}>
            <StyledScrollable
              direction="row"
              gap={2}
              sx={{
                overflowX: "auto",
                paddingBottom: 3,
              }}
            >
              {Array.from({ length: MAX_CHARS }).map((_, index) => (
                <CharView char={chars[index]} />
              ))}
            </StyledScrollable>
          </Box>
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <CharInfo accountId={accountId} charId={selectedChar} />
        </Stack>
      </Stack>
      <Box width="100%" my={3}>
        <Divider />
      </Box>
      <InventoryList accountId={accountId} charId={selectedChar} />
    </Stack>
  );
};

export default memo(CharList);
