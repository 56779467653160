import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import instance from "@/config/instance";
import { generatePath } from "react-router-dom";

export interface CharData {
  charId: number;
  accountId: number;
  charNum: number;
  name: string;
  _class: number;
  baseLevel: number;
  jobLevel: number;
  online: string;
  hair: number;
  sex: "M" | "F";
  lastMap: string;
  lastX: number;
  lastY: number;
}

export const CHARS_API = "/chars/:accountId";
export const useGetChars = (accountId?: string) => {
  const getCharsRequest = (): Promise<CharData[]> =>
    instance.get(generatePath(CHARS_API, { accountId: accountId! }));
  const { data, isLoading, ...rest } = useQuery<
    CharData[],
    any,
    CharData[],
    any
  >({
    queryKey: [CHARS_API, accountId],
    queryFn: getCharsRequest,
    retry: false,
    enabled: !!accountId,
  });

  return useMemo(() => {
    const chars = data ?? [];
    return {
      chars,
      isLoading,
      ...rest,
    };
  }, [isLoading, rest, accountId]);
};

export const CHAR_API = "/chars/:accountId/:charId";
export const useGetChar = (accountId?: string, charId?: string) => {
  const getCharRequest = (): Promise<CharData> =>
    instance.get(
      generatePath(CHAR_API, { accountId: accountId!, charId: charId! })
    );
  const { data, isLoading, ...rest } = useQuery<CharData, any, CharData, any>({
    queryKey: [CHAR_API, accountId, charId],
    queryFn: getCharRequest,
    retry: false,
    enabled: !!accountId && !!charId,
  });

  return useMemo(() => {
    return {
      char: data || ({} as CharData),
      isLoading,
      ...rest,
    };
  }, [isLoading, rest, data, accountId, charId]);
};
