import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import qs from "qs";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getQueryParam = useCallback(
    <T extends string | string[]>(queryStringName: string): T | undefined => {
      const parsed = qs.parse(searchParams.toString());
      return parsed[queryStringName] as T | undefined;
    },
    [searchParams]
  );

  const setQueryParam = useCallback(
    (paramsObj: Record<string, string | string[] | undefined>) => {
      const parsed = qs.parse(searchParams.toString());

      Object.entries(paramsObj).forEach(([key, value]) => {
        if (value) {
          parsed[key] = value;
        } else {
          delete parsed[key];
        }
      });

      const stringified = qs.stringify(parsed);
      setSearchParams(stringified);
      navigate({ search: stringified });
    },
    [searchParams, setSearchParams, navigate]
  );
  return { getQueryParam, setQueryParam };
};
