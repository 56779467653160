import { CharData } from "@/api/char";
import { ClassNum, ClassResource } from "@/constants/classNames";
import { getAssetUrl } from "@/utils/assets.utils";
import { Box } from "@mui/material";
import { t } from "i18next";
import { memo } from "react";

interface CharImageProps {
  char: CharData;
  suffix: string;
}

const CharImage = ({ char, suffix }: CharImageProps) => {
  const { sex, hair, _class } = char;
  return (
    <>
      <Box
        component="img"
        position="absolute"
        left="50%"
        top="calc(50% - 8px)"
        zIndex={1}
        sx={{ transform: "translate(-50%, -50%)" }}
        src={getAssetUrl(
          t("assets.char_head", {
            sex,
            hair,
            suffix,
          })
        )}
      />
      <Box
        component="img"
        position="absolute"
        top="calc(50% + 9px)"
        left="50%"
        sx={{ transform: "translate(-50%, -50%)" }}
        src={getAssetUrl(
          t("assets.char_body", {
            sex: char.sex,
            suffix,
            class:
              ClassResource[ClassNum[t("class_{{class}}", { class: _class })]],
          })
        )}
      />
    </>
  );
};

export default memo(CharImage);
