import { Stack } from "@mui/material";
import { memo } from "react";
import {
  StyledCharBox,
  StyledNewChar,
  StyledNewCharButton,
} from "../CharView.styles";
import { SlPlus } from "react-icons/sl";

const NewChar = () => {
  return (
    <StyledCharBox>
      <StyledNewChar>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
          gap={1}
        >
          <SlPlus />
          <StyledNewCharButton>Criar novo</StyledNewCharButton>
        </Stack>
      </StyledNewChar>
    </StyledCharBox>
  );
};

export default memo(NewChar);
