export enum ClassID {
  JT_NOVICE = 0,
  JT_SWORDMAN,
  JT_MAGICIAN,
  JT_ARCHER,
  JT_ACOLYTE,
  JT_MERCHANT,
  JT_THIEF,
  JT_KNIGHT,
  JT_PRIEST,
  JT_WIZARD,
  JT_BLACKSMITH,
  JT_HUNTER,
  JT_ASSASSIN,
  JT_CRUSADER,
  JT_MONK,
  JT_SAGE,
  JT_ROGUE,
  JT_ALCHEMIST,
  JT_BARD,
  JT_DANCER,
  JT_SUPERNOVICE = 23,
  JT_GUNSLINGER,
  JT_NINJA,
  JT_NOVICE_H = 4001,
  JT_SWORDMAN_H = 4002,
  JT_MAGICIAN_H = 4003,
  JT_ARCHER_H = 4004,
  JT_ACOLYTE_H = 4005,
  JT_MERCHANT_H = 4006,
  JT_THIEF_H = 4007,
  JT_KNIGHT_H = 4008,
  JT_PRIEST_H = 4009,
  JT_WIZARD_H = 4010,
  JT_BLACKSMITH_H = 4011,
  JT_HUNTER_H = 4012,
  JT_ASSASSIN_H = 4013,
  JT_CRUSADER_H = 4015,
  JT_MONK_H = 4016,
  JT_SAGE_H = 4017,
  JT_ROGUE_H = 4018,
  JT_ALCHEMIST_H = 4019,
  JT_BARD_H = 4020,
  JT_DANCER_H = 4021,
  JT_NOVICE_B = 4023,
  JT_SWORDMAN_B = 4024,
  JT_MAGICIAN_B = 4025,
  JT_ARCHER_B = 4026,
  JT_ACOLYTE_B = 4027,
  JT_MERCHANT_B = 4028,
  JT_THIEF_B = 4029,
  JT_KNIGHT_B = 4030,
  JT_PRIEST_B = 4031,
  JT_WIZARD_B = 4032,
  JT_BLACKSMITH_B = 4033,
  JT_HUNTER_B = 4034,
  JT_ASSASSIN_B = 4035,
  JT_CHICKEN_B = 4036,
  JT_CRUSADER_B = 4037,
  JT_MONK_B = 4038,
  JT_SAGE_B = 4039,
  JT_ROGUE_B = 4040,
  JT_ALCHEMIST_B = 4041,
  JT_BARD_B = 4042,
  JT_DANCER_B = 4043,
  JT_CHICKEN2_B = 4044,
  JT_SUPERNOVICE_B = 4045,
  JT_TAEKWON = 4046,
  JT_STAR = 4047,
  JT_STAR2 = 4048,
  JT_LINKER = 4049,
  JT_RUNE_KNIGHT = 4054,
  JT_WARLOCK = 4055,
  JT_RANGER = 4056,
  JT_ARCHBISHOP = 4057,
  JT_MECHANIC = 4058,
  JT_GUILLOTINE_CROSS = 4059,
  JT_RUNE_KNIGHT_H = 4060,
  JT_WARLOCK_H = 4061,
  JT_RANGER_H = 4062,
  JT_ARCHBISHOP_H = 4063,
  JT_MECHANIC_H = 4064,
  JT_GUILLOTINE_CROSS_H = 4065,
  JT_ROYAL_GUARD = 4066,
  JT_SORCERER = 4067,
  JT_MINSTREL = 4068,
  JT_WANDERER = 4069,
  JT_SURA = 4070,
  JT_GENETIC = 4071,
  JT_SHADOW_CHASER = 4072,
  JT_ROYAL_GUARD_H = 4073,
  JT_SORCERER_H = 4074,
  JT_MINSTREL_H = 4075,
  JT_WANDERER_H = 4076,
  JT_SURA_H = 4077,
  JT_GENETIC_H = 4078,
  JT_SHADOW_CHASER_H = 4079,
  JT_RUNE_KNIGHT_B = 4096,
  JT_WARLOCK_B = 4097,
  JT_RANGER_B = 4098,
  JT_ARCHBISHOP_B = 4099,
  JT_MECHANIC_B = 4100,
  JT_GUILLOTINE_CROSS_B = 4101,
  JT_ROYAL_GUARD_B = 4102,
  JT_SORCERER_B = 4103,
  JT_MINSTREL_B = 4104,
  JT_WANDERER_B = 4105,
  JT_SURA_B = 4106,
  JT_GENETIC_B = 4107,
  JT_SHADOW_CHASER_B = 4108,
  JT_SUPERNOVICE2 = 4190,
  JT_SUPERNOVICE2_B = 4191,
  JT_KAGEROU = 4211,
  JT_OBORO = 4212,
}

export const ClassNum: { [key: string]: string } = {
  class_0: "JT_NOVICE",
  class_1: "JT_SWORDMAN",
  class_2: "JT_MAGICIAN",
  class_3: "JT_ARCHER",
  class_4: "JT_ACOLYTE",
  class_5: "JT_MERCHANT",
  class_6: "JT_THIEF",
  class_7: "JT_KNIGHT",
  class_8: "JT_PRIEST",
  class_9: "JT_WIZARD",
  class_10: "JT_BLACKSMITH",
  class_11: "JT_HUNTER",
  class_12: "JT_ASSASSIN",
  class_13: "JT_CRUSADER",
  class_14: "JT_MONK",
  class_15: "JT_SAGE",
  class_16: "JT_ROGUE",
  class_17: "JT_ALCHEMIST",
  class_18: "JT_BARD",
  class_19: "JT_DANCER",
  class_23: "JT_SUPERNOVICE",
  class_24: "JT_GUNSLINGER",
  class_25: "JT_NINJA",
  class_4001: "JT_NOVICE_H",
  class_4002: "JT_SWORDMAN_H",
  class_4003: "JT_MAGICIAN_H",
  class_4004: "JT_ARCHER_H",
  class_4005: "JT_ACOLYTE_H",
  class_4006: "JT_MERCHANT_H",
  class_4007: "JT_THIEF_H",
  class_4008: "JT_KNIGHT_H",
  class_4009: "JT_PRIEST_H",
  class_4010: "JT_WIZARD_H",
  class_4011: "JT_BLACKSMITH_H",
  class_4012: "JT_HUNTER_H",
  class_4013: "JT_ASSASSIN_H",
  class_4015: "JT_CRUSADER_H",
  class_4016: "JT_MONK_H",
  class_4017: "JT_SAGE_H",
  class_4018: "JT_ROGUE_H",
  class_4019: "JT_ALCHEMIST_H",
  class_4020: "JT_BARD_H",
  class_4021: "JT_DANCER_H",
  class_4023: "JT_NOVICE_B",
  class_4024: "JT_SWORDMAN_B",
  class_4025: "JT_MAGICIAN_B",
  class_4026: "JT_ARCHER_B",
  class_4027: "JT_ACOLYTE_B",
  class_4028: "JT_MERCHANT_B",
  class_4029: "JT_THIEF_B",
  class_4030: "JT_KNIGHT_B",
  class_4031: "JT_PRIEST_B",
  class_4032: "JT_WIZARD_B",
  class_4033: "JT_BLACKSMITH_B",
  class_4034: "JT_HUNTER_B",
  class_4035: "JT_ASSASSIN_B",
  class_4036: "JT_CHICKEN_B",
  class_4037: "JT_CRUSADER_B",
  class_4038: "JT_MONK_B",
  class_4039: "JT_SAGE_B",
  class_4040: "JT_ROGUE_B",
  class_4041: "JT_ALCHEMIST_B",
  class_4042: "JT_BARD_B",
  class_4043: "JT_DANCER_B",
  class_4044: "JT_CHICKEN2_B",
  class_4045: "JT_SUPERNOVICE_B",
  class_4046: "JT_TAEKWON",
  class_4047: "JT_STAR",
  class_4048: "JT_STAR2",
  class_4049: "JT_LINKER",
  class_4054: "JT_RUNE_KNIGHT",
  class_4055: "JT_WARLOCK",
  class_4056: "JT_RANGER",
  class_4057: "JT_ARCHBISHOP",
  class_4058: "JT_MECHANIC",
  class_4059: "JT_GUILLOTINE_CROSS",
  class_4060: "JT_RUNE_KNIGHT_H",
  class_4061: "JT_WARLOCK_H",
  class_4062: "JT_RANGER_H",
  class_4063: "JT_ARCHBISHOP_H",
  class_4064: "JT_MECHANIC_H",
  class_4065: "JT_GUILLOTINE_CROSS_H",
  class_4066: "JT_ROYAL_GUARD",
  class_4067: "JT_SORCERER",
  class_4068: "JT_MINSTREL",
  class_4069: "JT_WANDERER",
  class_4070: "JT_SURA",
  class_4071: "JT_GENETIC",
  class_4072: "JT_SHADOW_CHASER",
  class_4073: "JT_ROYAL_GUARD_H",
  class_4074: "JT_SORCERER_H",
  class_4075: "JT_MINSTREL_H",
  class_4076: "JT_WANDERER_H",
  class_4077: "JT_SURA_H",
  class_4078: "JT_GENETIC_H",
  class_4079: "JT_SHADOW_CHASER_H",
  class_4096: "JT_RUNE_KNIGHT_B",
  class_4097: "JT_WARLOCK_B",
  class_4098: "JT_RANGER_B",
  class_4099: "JT_ARCHBISHOP_B",
  class_4100: "JT_MECHANIC_B",
  class_4101: "JT_GUILLOTINE_CROSS_B",
  class_4102: "JT_ROYAL_GUARD_B",
  class_4103: "JT_SORCERER_B",
  class_4104: "JT_MINSTREL_B",
  class_4105: "JT_WANDERER_B",
  class_4106: "JT_SURA_B",
  class_4107: "JT_GENETIC_B",
  class_4108: "JT_SHADOW_CHASER_B",
  class_4190: "JT_SUPERNOVICE2",
  class_4191: "JT_SUPERNOVICE2_B",
  class_4211: "JT_KAGEROU",
  class_4212: "JT_OBORO",
};

export const ClassResource: { [key: string]: string } = {
  // 1-1
  JT_NOVICE: "ÃÊº¸ÀÚ",
  JT_SWORDMAN: "°Ë»ç",
  JT_MAGICIAN: "¸¶¹ý»ç",
  JT_ARCHER: "±Ã¼ö",
  JT_ACOLYTE: "¼ºÁ÷ÀÚ",
  JT_MERCHANT: "»óÀÎ",
  JT_THIEF: "µµµÏ",
  // 2-1
  JT_KNIGHT: "±â»ç",
  JT_PRIEST: "ÇÁ¸®½ºÆ®",
  JT_WIZARD: "À§Àúµå",
  JT_BLACKSMITH: "Á¦Ã¶°ø",
  JT_HUNTER: "ÇåÅÍ",
  JT_ASSASSIN: "¾î¼¼½Å",
  // 2-1 TRANS
  JT_KNIGHT_H: "·Îµå³ªÀÌÆ®",
  JT_PRIEST_H: "¼ºÅõ»ç2",
  JT_WIZARD_H: "ÇÏÀÌÀ§Àúµå",
  JT_BLACKSMITH_H: "È­ÀÌÆ®½º¹Ì½º",
  JT_HUNTER_H: "½º³ªÀÌÆÛ",
  JT_ASSASSIN_H: "¾î½Ø½ÅÅ©·Î½º",
  // 2-2
  JT_CRUSADER: "Å©·ç¼¼ÀÌ´õ",
  JT_MONK: "¸ùÅ©",
  JT_SAGE: "¼¼ÀÌÁö",
  JT_ROGUE: "·Î±×",
  JT_ALCHEMIST: "¿¬±Ý¼ú»ç",
  JT_BARD: "¹Ùµå",
  JT_DANCER: "¹«Èñ",
  // 2-2 TRANS
  JT_CRUSADER_H: "¾î½Ø½ÅÅ©·Î½º",
  JT_MONK_H: "Ã¨ÇÇ¿Â",
  JT_SAGE_H: "ÇÁ·ÎÆä¼­",
  JT_ROGUE_H: "½ºÅäÄ¿",
  JT_ALCHEMIST_H: "Å©¸®¿¡ÀÌÅÍ",
  JT_BARD_H: "Å¬¶ó¿î",
  JT_DANCER_H: "Áý½Ã",
};
