import React, { FC, memo, useCallback, useState, useEffect } from "react";
import { Box, Stack, TextField } from "@mui/material";
import type {
  FilledTextFieldProps as FTFP,
  OutlinedTextFieldProps as OTFP,
  StandardTextFieldProps as STFP,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { StyledLabel } from "./NumberField.styles";
import { formatNumber } from "@/utils/numberFormat.utils";

interface CommonTextFieldProps {
  name: string;
  formik?: boolean;
  max?: number;
  min?: number;
  step?: number;
  isDecimal?: boolean;
  isCPF?: boolean;
  isCard?: boolean;
  isZip?: boolean;
  isPhone?: boolean;
  decimalSeparator?: string;
  thousandSeparator?: string;
  action?: JSX.Element;
}

export type TextFieldProps =
  | (CommonTextFieldProps & STFP)
  | (CommonTextFieldProps & FTFP)
  | (CommonTextFieldProps & OTFP);

const NumberField: FC<TextFieldProps> = (props) => {
  const {
    name,
    formik = true,
    label,
    max,
    min,
    step,
    isDecimal = false,
    isCPF = false,
    isCard = false,
    isZip = false,
    isPhone = false,
    decimalSeparator = ",",
    thousandSeparator = ".",
    action,
    ...rest
  } = props;
  const formikContext = useFormikContext();
  const isFormik = formik && formikContext;

  const formikFieldProps = isFormik ? getIn(formikContext.values, name) : "";
  const formikFieldError = isFormik ? getIn(formikContext.errors, name) : "";
  const formikFieldTouched = isFormik ? getIn(formikContext.touched, name) : "";
  const validate = isFormik ? formikContext.validateField : undefined;

  const value = isFormik ? formikFieldProps : rest.value;
  const onChange = isFormik ? formikContext.handleChange : rest.onChange;
  const [innerValue, setInnerValue] = useState<string | undefined>(
    formatNumber(
      value as string,
      isDecimal,
      isCPF,
      isCard,
      isZip,
      isPhone,
      decimalSeparator,
      thousandSeparator
    )
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let rawValue = event?.target?.value.replace(/[^\d]/g, ""); // Remove caracteres não numéricos

      if (max) {
        rawValue = Math.min(max, Number(rawValue)).toString();
      }
      if (min) {
        rawValue = Math.max(min, Number(rawValue)).toString();
      }

      if (isCPF) {
        rawValue = rawValue.slice(0, 11);
      }
      if (isPhone) {
        rawValue = rawValue.slice(0, 11);
      }

      if (isCard) {
        rawValue = rawValue.slice(0, 8);
      }

      if (isZip) {
        rawValue = rawValue.slice(0, 8);
      }

      const formattedValue = formatNumber(
        rawValue,
        isDecimal,
        isCPF,
        isCard,
        isZip,
        isPhone,
        decimalSeparator,
        thousandSeparator
      );

      setInnerValue(formattedValue);
      event.target.value = rawValue;
      onChange?.(event);
    },
    [
      decimalSeparator,
      isDecimal,
      isCPF,
      isPhone,
      isZip,
      thousandSeparator,
      max,
      min,
      name,
      isFormik,
      formikContext,
    ]
  );

  useEffect(() => {
    setInnerValue(
      formatNumber(
        (value as string) || "",
        isDecimal,
        isCPF,
        isCard,
        isZip,
        isPhone,
        decimalSeparator,
        thousandSeparator
      )
    );
  }, [value]);
  return (
    <Stack width="100%">
      <StyledLabel>{label}</StyledLabel>
      <TextField
        {...rest}
        name={name} // Garante que o nome está sendo passado
        value={innerValue}
        onChange={handleChange}
        onBlur={() => validate?.(name)}
        inputProps={{ inputMode: "numeric", max, min, step }}
        error={
          isFormik
            ? Boolean(formikFieldTouched && formikFieldError)
            : rest.error
        }
        helperText={
          <Box component="span" color={formikFieldError ? "red" : "inherit"}>
            {isFormik
              ? formikFieldTouched && formikFieldError
                ? formikFieldError
                : rest.helperText
              : rest.helperText}
          </Box>
        }
        InputProps={{
          endAdornment: action,
        }}
      />
    </Stack>
  );
};

export default memo(NumberField);
