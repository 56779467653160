import { Theme } from "@mui/material";

export const toggleButtonStyle: Partial<Theme["components"]> = {
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderWidth: 2,
        borderColor: "#E6E8EC",
        fontSize: 14,
        textTransform: "none",
        color: "#23262F",
        fontWeight: 600,
        fontFamily: "DM Sans",
        padding: "7px 16px",
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      firstButton: {
        borderRadius: "50px 0 0 50px",
      },
      lastButton: {
        borderRadius: "0 50px 50px 0",
      },
    },
  },
};
