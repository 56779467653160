import React, { FC, memo, useCallback, useEffect, useState } from "react";
import type {
  FilledTextFieldProps as FTFP,
  OutlinedTextFieldProps as OTFP,
  StandardTextFieldProps as STFP,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { Box, Button, TextField as MuiTextField, Stack } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { StyledLabel } from "./TextField.styles";

const INPUT_DELAY = 200;

interface CommonTextFieldProps {
  name: string;
  formik?: boolean;
  action?: JSX.Element;
}

export type TextFieldProps =
  | (CommonTextFieldProps & STFP)
  | (CommonTextFieldProps & FTFP)
  | (CommonTextFieldProps & OTFP);

const TextField: FC<TextFieldProps> = (props) => {
  const { name, formik, label, action, ...rest } = props;
  const formikContext = useFormikContext();

  const isFormik = (formik === undefined || formik) && formikContext;
  const formikFieldProps = isFormik ? getIn(formikContext.values, name) : null;
  const formikFieldError = isFormik ? getIn(formikContext.errors, name) : null;
  const formikFieldTouched = isFormik
    ? getIn(formikContext.touched, name)
    : null;

  //@ts-ignore
  const value = isFormik ? formikFieldProps : rest.value;
  const onChange = isFormik ? formikContext.handleChange : rest.onChange;
  const [innerValue, setInnerValue] = useState<string>(value as string);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    INPUT_DELAY
  );

  useEffect(() => {
    if (value === null || value === undefined) {
      return setInnerValue("");
    }
    setInnerValue(value as string);
  }, [value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event?.persist?.();
      setInnerValue(event?.target?.value);
      debouncedHandleOnChange(event);
    },
    [debouncedHandleOnChange]
  );

  return (
    <Stack width="100%">
      {label && <StyledLabel>{label}</StyledLabel>}
      <MuiTextField
        {...rest}
        name={name}
        value={innerValue}
        onChange={handleChange}
        error={
          isFormik
            ? Boolean(formikFieldTouched && formikFieldError)
            : rest.error
        }
        helperText={
          (rest.helperText || formikFieldError) && (
            <Box component="span" color={formikFieldError ? "red" : "inherit"}>
              {isFormik
                ? formikFieldTouched && formikFieldError
                  ? formikFieldError
                  : rest.helperText
                : rest.helperText}
            </Box>
          )
        }
        fullWidth
        InputProps={{
          endAdornment: action,
        }}
      />
    </Stack>
  );
};

export default memo(TextField);
