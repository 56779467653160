import React, { FC, memo } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getIn, useFormikContext } from "formik";
import { StyledLabel } from "./DateField.styles";
import { DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { TbCalendarEvent } from "react-icons/tb";
import { ptBR } from "date-fns/locale";

interface CommonDateFieldProps {
  name: string;
  formik?: boolean;
  action?: JSX.Element;
  label: string;
  helperText?: string;
}

export type DateFieldProps = CommonDateFieldProps & DatePickerProps<any>;

const DateField: FC<DateFieldProps> = (props) => {
  const { name, formik = true, label, action, ...rest } = props;
  const formikContext = useFormikContext();
  const isFormik = formik && formikContext;

  const formikFieldProps = isFormik ? getIn(formikContext.values, name) : null;
  const formikFieldError = isFormik ? getIn(formikContext.errors, name) : "";
  const formikFieldTouched = isFormik ? getIn(formikContext.touched, name) : "";

  const value = isFormik ? formikFieldProps : rest.value;
  const onChange = isFormik ? formikContext.setFieldValue : rest.onChange;

  const handleChange = (date: Date | null) => {
    onChange?.(name, date);
  };

  return (
    <Stack width="100%">
      <StyledLabel>{label}</StyledLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DatePicker
          {...rest}
          value={value}
          onChange={handleChange}
          format="dd MMM, yyyy"
          slots={{ openPickerIcon: TbCalendarEvent }}
          slotProps={{
            textField: {
              helperText: isFormik
                ? formikFieldTouched && formikFieldError
                  ? formikFieldError
                  : rest.helperText
                : rest.helperText,
            },
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default memo(DateField);
