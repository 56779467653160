import { PaletteOptions } from "@mui/material/styles";

const palette: PaletteOptions = {
  primary: {
    main: "#1976d2",
  },
  secondary: {
    main: "#dc004e",
  },
  background: {
    default: "#f4f6f8",
  },
};

export default palette;
