import { Theme } from "@mui/material";

export const buttonStyle: Partial<Theme["components"]> = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 600,
        fontFamily: "DM Sans",
        padding: "12px 24px",
        textTransform: "none",
      },
      contained: {
        background: "#777E90",
        boxShadow: "none",
        borderRadius: 50,
        "&:hover": {
          boxShadow: "0px 14px 20px 7px #00000021",
          background: "#141416",
        },
      },
      text: {
        borderRadius: 50,
        color: "#777E90",
        "&:hover": {
          color: "#141416",
        },
      },
      sizeSmall: {
        fontSize: 14,
        padding: "2px 8px",
      },
      outlined: {
        maxWidth: "fit-content",
        width: "-webkit-fill-available",
        borderWidth: 2,
        borderRadius: 50,
        borderColor: "#E6E8EC",
        fontSize: 14,
        color: "#23262F",
        "&:hover": {
          borderColor: "#141416",
          borderWidth: 2,
        },
        "&.Mui-disabled": {
          borderWidth: 2,
        },
      },
    },
  },
};
