import { AccountData } from "@/api/account";
import { Grid, Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import { SlPlus } from "react-icons/sl";
import { MAX_ACCOUNT } from "../GameSettings";
import { formatDateLong } from "@/utils/date.utils";
import { useAccountView } from "../useGameSettings";

interface AccountCardProps {
  account?: AccountData;
}

const AccountCard: FC<AccountCardProps> = ({ account }) => {
  const { isSelected } = useAccountView(account);

  if (!account) {
    return (
      <Grid item xs={12 / MAX_ACCOUNT}>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{
            width: "100%",
            height: 76,
            borderRadius: "16px",
            background: "#F7F7F7",
            color: "#626264",
            cursor: "pointer",
            transition: "0.3s all",
            border: "2px solid transparent",
            "&:hover": {
              borderColor: "#353945",
              color: "#353945",
            },
          }}
        >
          <Typography variant="body1">Criar conta</Typography>
          <SlPlus />
        </Stack>
      </Grid>
    );
  }

  return (
    <Grid item xs={12 / MAX_ACCOUNT}>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{
          width: "100%",
          height: 76,
          borderRadius: "16px",
          color: "#626264",
          cursor: "pointer",
          transition: "0.3s all",
          border: "2px solid transparent",
          borderColor: isSelected ? "#d8d8d8" : "transparent",
          "&:hover": {
            borderColor: "#d8d8d8",
            color: "#353945",
          },
        }}
      >
        <Stack gap={0.5}>
          <Typography variant="body1">
            {account.userid} #{account.accountId}
          </Typography>
          <Stack>
            {account.lastlogin && (
              <Typography variant="subtitle1">
                Ultimo login{" "}
                <strong>{formatDateLong(account.lastlogin)}</strong>
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default memo(AccountCard);
