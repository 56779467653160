import { Button, Stack, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useGetUser, useUpdateUser } from "@/api/user";
import { Form } from "@/components/Form";
import { MdClose } from "react-icons/md";
import { useInitializeAccountInfoForm } from "./useAccountInfoForm";
import { useInitializeAddressForm } from "./useAddressForm";
import { FormikValues } from "formik";
import { diffObjects } from "@/utils/form.utils";
import { omit } from "lodash";

const ProfileSettings = () => {
  const { user } = useGetUser();
  const { trigger } = useUpdateUser();

  const {
    fields: accountInfoFields,
    initialValues: accountInfoInitialValues,
    validationSchema: accountInfoValidation,
  } = useInitializeAccountInfoForm(user);
  const {
    fields: addressFields,
    initialValues: addressInitialValues,
    validationSchema: addressValidation,
  } = useInitializeAddressForm(user);

  const initialValues = {
    ...accountInfoInitialValues,
    ...addressInitialValues,
  };

  const validationSchema = {
    ...accountInfoValidation,
    ...addressValidation,
  };

  const handleSubmit = (values: FormikValues) => {
    const diff = diffObjects(
      omit(values, [
        "addressLine1",
        "addressCity",
        "addressState",
        "addressNeighborhood",
      ]),
      initialValues
    );
    if (Object.keys(diff).length > 0) {
      trigger({ id: user?.id, payload: diff });
    }
  };

  const formBoxes = [
    {
      title: "Dados da Conta",
      fields: accountInfoFields,
    },
    {
      title: "Informações de Endereço",
      fields: addressFields,
    },
  ];

  return (
    <Stack direction="column" width="100%">
      <Typography variant="h2" mb={8}>
        Informações Pessoais
      </Typography>
      <Form
        formBoxes={formBoxes}
        spacing={2.5}
        mt={1.5}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur
        validateOnChange
        validateOnMount={false}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ values, setValues, isValid }) => {
          return (
            <Stack direction="row" justifyContent="flex-start" gap={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  !isValid || (values.addressZipcode && !values.addressLine1)
                }
                onClick={() => handleSubmit(values)}
              >
                Atualizar perfil
              </Button>
              <Button variant="text" onClick={() => setValues(initialValues)}>
                <Stack direction="row" gap={1} alignItems="center">
                  <MdClose />
                  Apagar alterações
                </Stack>
              </Button>
            </Stack>
          );
        }}
      </Form>
    </Stack>
  );
};

export default memo(ProfileSettings);
