import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useGetUser } from "@/api/user";
import { formatDateLong } from "@/utils/date.utils";
import { CellField } from "@/components/CellField";

const Security = () => {
  const { user } = useGetUser();

  return (
    <Stack direction="column" width="100%">
      <Typography variant="h2" mb={8}>
        Login e Segurança
      </Typography>
      <Stack gap={2}>
        <Typography variant="h5">Segurança</Typography>
        <Grid container columns={14} justifyContent="space-between">
          <CellField
            size={6}
            title="Autenticação em 2 etapas"
            subtitle="Adicione uma camada extre de segurança ao se contectar em nosso painel."
            value="Desativado"
            action={<Button variant="outlined">Ativar</Button>}
          />
          <Grid xs="auto" mx={6}>
            <Divider orientation="vertical" />
          </Grid>
          <CellField
            size={6}
            title="Contas in-game"
            subtitle="Você pode desativar o login de suas contas in-game por um tempo."
            value="Habilitado"
            action={<Button variant="outlined">Desativar</Button>}
          />
        </Grid>
      </Stack>
      <Box width="100%" my={6}>
        <Divider />
      </Box>
      <Stack gap={2}>
        <Typography variant="h5">Social</Typography>
        <Grid container columns={12}>
          <CellField
            title="Discord"
            subtitle="Ative a integração para que possamos interagir diretamente com seu discord"
            value="Desativado"
            action={<Button variant="outlined">Conectar</Button>}
          />
        </Grid>
      </Stack>
      <Box width="100%" my={6}>
        <Divider />
      </Box>
      <Stack gap={2}>
        <Typography variant="h5">Histórico de sessão</Typography>
        <Grid container columns={12}>
          <CellField
            title="macOs Big Sur. Chrome"
            value={formatDateLong(new Date().toDateString())}
            action={<Button variant="outlined">Encerrar</Button>}
          />
          <Box width="100%" my={2}>
            <Divider />
          </Box>
          <CellField
            title="macOs Big Sur. Chrome"
            value={formatDateLong(new Date().toDateString())}
            action={<Button variant="outlined">Encerrar</Button>}
          />
          <Box width="100%" my={2}>
            <Divider />
          </Box>
          <CellField
            title="macOs Big Sur. Chrome"
            value={formatDateLong(new Date().toDateString())}
            action={<Button variant="outlined">Encerrar</Button>}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default memo(Security);
