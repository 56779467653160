import i18n from "i18next";
import { initReactI18next } from "react-i18next";

interface Translation {
  [key: string]: string | number | boolean | Translation | Translation[];
}

const loadTranslations = (modules: Record<string, unknown>) => {
  const translations: { [lang: string]: Translation } = {};

  Object.entries(modules).forEach(([path, module]) => {
    const match = path.match(/^\.\/(.*?)\/(.*?)\/(.*)\.json$/);
    if (match) {
      const [, , , filePath] = match;
      const key = filePath.replace(/\//g, ".");
      translations[key] = (module as { default: Translation }).default;
    }
  });

  return translations;
};

const pt = loadTranslations(
  import.meta.glob("./translations/pt/**/*.json", {
    eager: true,
  })
);

i18n.use(initReactI18next).init({
  fallbackLng: "pt",
  resources: {},
  lng: localStorage.getItem("language") || "pt",
  interpolation: { escapeValue: false },
});

export default i18n.addResourceBundle("pt", "translation", pt);
