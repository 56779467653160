import { Field } from "@/components/FormField/FormField.types";
import * as yup from "yup";

const cardFields: { [key: string]: Field } = {
  cardNumber: {
    columns: 12,
    label: "Número do cartão",
    componentType: "number",
  },
  cardHolder: {
    columns: 12,
    label: "Nome no cartão",
  },
  expiration: {
    columns: 6,
    label: "Expira em (MM/YY)",
    componentType: "date",
  },
  cvc: {
    columns: 6,
    label: "CVC",
  },
};

const validationSchema = {
  cardNumber: yup
    .number()
    .min(16)
    .max(16)
    .required("Número do cartão é obrigatório"),
  cardHolder: yup.string().required("Nome no cartão é obrigatório"),
  expiration: yup.string().required("Data de expiração é obrigatória"),
  cvc: yup.string().required("CVC é obrigatório"),
};

export const useInitializeCardForm = (cardData: any) => {
  return {
    fields: cardFields,
    initialValues: {
      cardNumber: "",
      cardHolder: "",
      expiration: "",
      cvc: "",
    },
    validationSchema,
  };
};
