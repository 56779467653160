import { CardPrefix } from "@/constants/cardPrefix.constant";
import { ItemType } from "@/types/ItemType";
import { ReactNode } from "react";

export const getItemName = ({
  item,
  showSlots = false,
}: {
  item?: ItemType;
  showSlots?: boolean;
}) => {
  if (!item) return "";

  const cardNaming = item.slots > 0 ? getCardNaming(item.cards) : "";
  return `${item.description.displayName}${cardNaming}${
    showSlots && item.slots > 0 ? ` [${item.slots}]` : ""
  }`;
};

export const getCardNaming = (cards?: ItemType["cards"]) => {
  if (!cards) return null;

  const cardCounting: { [key: string]: number } = cards.reduce<{
    [key: string]: number;
  }>((acc, card) => {
    if (card) {
      // @ts-ignore
      const cardPrefix = CardPrefix["id_" + card.id];
      acc[cardPrefix] = Number(acc[cardPrefix] || 0) + 1;
    }
    return acc;
  }, {});

  const cardSuffix = ["", "Bi", "Tri", "Quad"];

  return Object.entries(cardCounting)
    .map(([key, value]) => {
      if (value === 0) return "";
      return ` ${key} ${cardSuffix[value - 1]}`;
    })
    .join("");
};

export const getisIdentified = (item?: ItemType) =>
  item?.identify === undefined || item?.identify === 1;

export const parseDescription = (input: string): ReactNode[] => {
  const regex = /\^([A-Fa-f0-9]{6})(.*?)\^000000/g;
  const parts: ReactNode[] = [];
  let lastIndex = 0;

  input.replace(regex, (match, p1, p2, offset) => {
    if (offset > lastIndex) {
      parts.push(input.substring(lastIndex, offset));
    }

    parts.push(<span style={{ color: `#${p1}` }}>{p2}</span>);

    lastIndex = offset + match.length;
    return match;
  });

  // Adiciona a parte restante da string após o último match
  if (lastIndex < input.length) {
    parts.push(input.substring(lastIndex));
  }

  return parts;
};
