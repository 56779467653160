import { MessageType, assert } from "./assert.utils";

// Assertions
const isBooleanString = (value: string): boolean =>
  value === "true" || value === "false";
//

const required = (message: MessageType) => {
  throw new Error(message as string);
};

const resolveValue = (
  value: string | boolean | void
): string | boolean | void => {
  if (typeof value === "string" && isBooleanString(value)) {
    return value === "true";
  }
  return value;
};

export const requiredValue = (
  key: string,
  assertions: Array<(_value: unknown) => string> = []
): string => {
  if (!key) {
    required("Key is required");
  }

  const value = import.meta.env[key] || "";

  assert(key in import.meta.env, `Env variable is not defined: ${key}`);
  assertions.forEach((assertion) => {
    assert(assertion(value), `${key} assertion error: ${assertion.name}`);
  });

  return resolveValue(value) as string;
};

export const configValue = (key: string, defaultValue: string): string => {
  const targetKey: string = key.trim();
  if (targetKey in import.meta.env) {
    const value = import.meta.env[targetKey];
    return resolveValue(value) as string;
  }

  return defaultValue;
};
