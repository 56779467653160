import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import instance from "@/config/instance";
import { isEmpty, reject } from "lodash";
import { ApiResponse } from "@/types/ApiResponse";
import { generatePath } from "react-router-dom";
import { useSnackbar } from "@/providers/SnackbarProvider";
import { queryClient } from "@/config/queryClient";

export const PUBLIC_AUTH_TOKEN_COOKIE = "er-public-cookie";

export interface User {
  id: string;
  email: string;
  birthdate: string;
  imageUrl: string | null;
  status: string;
  roles: string;
  permissions: string | null;
  phone: string | null;
  phoneVerified: boolean | null;
  phoneVerificationToken: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  displayName: string;
  fullName: string;
  identity: string;
  identityVerified: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressCountry: string | null;
  addressNumber: string | null;
  addressNeighborhood: string | null;
  addressZipcode: string | null;
  zeny: number;
  balance: number;
}

export const CURRENT_USER_API = "/auth/me";
export const useFetchUser = () => {
  const [cookies] = useCookies();
  const cookieUser = cookies[PUBLIC_AUTH_TOKEN_COOKIE];

  const getCurrentUser = (): Promise<User> => instance.get(CURRENT_USER_API);
  const {
    data: user,
    isLoading,
    ...rest
  } = useQuery<User, any, User, any>({
    queryKey: [CURRENT_USER_API],
    queryFn: getCurrentUser,
    retry: false,
    enabled: Boolean(cookieUser),
    select: (user) => {
      return new UserData(user);
    },
  });

  return useMemo(
    () => ({
      user: user as UserData,
      isLoading,
      ...rest,
    }),
    [isLoading, rest, user]
  );
};

export const useGetUser = () => {
  const { user, isLoading } = useFetchUser();

  return useMemo(
    () => ({
      user,
      isLoading,
    }),
    [isLoading, user]
  );
};

type UpdateUserPayload = {
  id: string;
  payload: {
    fullName?: string;
    displayName?: string;
    identity?: string;
    phone?: string;
    addressLine2?: string;
    addressNumber?: string;
    addressZipcode?: string;
  };
};

const USER_API = "/users/:id";
export const useUpdateUser = () => {
  const { pushSnackbar } = useSnackbar();
  const updateUserRequest = ({
    id,
    payload,
  }: UpdateUserPayload): Promise<ApiResponse<any>> =>
    instance.patch(generatePath(USER_API, { id }), payload);
  const updateUserMutation = useMutation<
    ApiResponse<any>,
    any,
    UpdateUserPayload
  >({
    mutationKey: [USER_API],
    mutationFn: updateUserRequest,
    onSuccess: () => {
      pushSnackbar({
        message: `Suas informações foram salvas com sucesso`,
        type: "SUCCESS",
        title: "Perfil atualizado",
      });
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_API] });
    },
  });
  const onUpdateUser = (params: UpdateUserPayload) =>
    updateUserMutation.mutateAsync(params);

  return {
    trigger: onUpdateUser,
    ...updateUserMutation,
  };
};

export class UserData implements User {
  id: string;
  email: string;
  birthdate: string;
  imageUrl: string | null;
  status: string;
  roles: string;
  permissions: string | null;
  phone: string | null;
  phoneVerified: boolean | null;
  phoneVerificationToken: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  displayName: string;
  fullName: string;
  identity: string;
  identityVerified: boolean;
  addressLine1: string | null;
  addressLine2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressCountry: string | null;
  addressNumber: string | null;
  addressNeighborhood: string | null;
  addressZipcode: string | null;
  zeny: number;
  balance: number;

  constructor(user: User) {
    Object.assign(this, user);
  }

  get initials() {
    if (this.displayName) {
      const [firstName, lastName] = this.displayName.split(" ");
      return lastName
        ? `${firstName.charAt(0)}${lastName.charAt(0)}`
        : `${firstName.charAt(0)}${firstName.charAt(1)}`;
    }
    return "NA";
  }
}
