import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useGetUser } from "@/api/user";
import { useGetAccounts } from "@/api/account";
import AccountCard from "./components/AccountCard";
import { formatDateLong } from "@/utils/date.utils";
import { Button } from "@/components/Button";
import StorageList from "./components/StorageList";
import CharList from "./components/CharList";
import { useQueryParams } from "@/hooks/useQueryParams";

export const MAX_ACCOUNT = 4;

const GameSettings = () => {
  const { user } = useGetUser();
  const { accounts } = useGetAccounts(user?.id);
  const { getQueryParam, setQueryParam } = useQueryParams();
  const selectedAccount = getQueryParam<string>("accountId");

  useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setQueryParam({ accountId: accounts[0].accountId.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  return (
    <Stack direction="column" width="100%">
      <Typography variant="h2" mb={8}>
        Contas do Jogo
      </Typography>

      <Stack gap={4}>
        <Typography variant="h5">Minhas contas</Typography>
        <Grid container spacing={2}>
          {Array.from({ length: MAX_ACCOUNT }).map((_, index) => {
            const account = accounts?.[index];
            return <AccountCard key={account?.accountId} account={account} />;
          })}
        </Grid>
      </Stack>

      {selectedAccount && (
        <>
          <Box width="100%" my={3}>
            <Divider />
          </Box>
          <Stack gap={3}>
            <Grid container columns={14}>
              <Grid
                item
                xs={6}
                height="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Stack gap={0.5}>
                  <Typography variant="body1">Senha</Typography>
                  <Typography variant="subtitle1">
                    Ultima alterção{" "}
                    <strong>{formatDateLong(new Date().toDateString())}</strong>
                  </Typography>
                </Stack>
                <Button variant="outlined" size="small">
                  Alterar
                </Button>
              </Grid>
              <Grid xs="auto" ml={6} mr={6}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                xs={6}
                height="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Stack gap={0.5}>
                  <Typography variant="body1">Pincode</Typography>
                  <Typography variant="subtitle1">Desativado</Typography>
                </Stack>
                <Button variant="outlined" size="small">
                  Ativar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
      <Box width="100%" my={3}>
        <Divider />
      </Box>
      <StorageList accountId={selectedAccount} />
      <Box width="100%" my={6}>
        <Divider />
      </Box>
      <CharList accountId={selectedAccount} />
    </Stack>
  );
};

export default memo(GameSettings);
