import { Theme } from "@mui/material";

export const inputStyle: Partial<Theme["components"]> = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#141416",
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f2f2f2",
          caretColor: "transparent",
        },
        "&.Mui-disabled": {
          caretColor: "transparent",
        },
      },
      input: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 600,
        color: "#141416",
        padding: "12px 16px",
        height: "unset",
      },
      notchedOutline: {
        borderColor: "#E6E8EC",
        borderWidth: "2px",
        "&.MuiFocused": {
          display: "none",
        },
      },
      focused: {
        display: "none",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 11,
        fontFamily: "Poppins",
        marginTop: "5px",
        marginLeft: 0,
        marginRight: 0,
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.6)",
        },
      },
    },
  },
};
