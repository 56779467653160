import { Box, Button } from "@mui/material";
import { FC, memo } from "react";
import { HiBadgeCheck } from "react-icons/hi";

interface CPFValidationProps {
  isValidated: boolean;
}

const CPFValidation: FC<CPFValidationProps> = ({ isValidated }) => {
  if (isValidated) {
    return (
      <Box
        component={HiBadgeCheck}
        sx={{
          fontSize: 24,
          color: "#89bc89",
        }}
      />
    );
  }
  return (
    <Button variant="outlined" size="small">
      Validar CPF
    </Button>
  );
};

export default memo(CPFValidation);
