import { Divider, Grid, Stack, Typography } from "@mui/material";
import { Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { FC, PropsWithChildren, ReactNode, memo } from "react";
import { FormField } from "@/components/FormField";
import { Field } from "@/components/FormField/FormField.types";
import * as Yup from "yup";

interface FormProps extends FormikConfig<any> {
  formBoxes: { title: string; fields: { [key: string]: Field } }[];
  spacing?: number;
  mt?: number;
  children: (props: FormikProps<any>) => ReactNode;
}

export interface FormItem {
  label: string;
  type?: string;
  disabled?: boolean;
  action?: JSX.Element;
  columns?:
    | number
    | {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
      };
}

const Form: FC<FormProps> = ({
  formBoxes,
  spacing = 2,
  mt = 0,
  children,
  validationSchema: formValidationSchema,
  ...props
}) => {
  const validationSchema = Yup.object().shape(formValidationSchema);

  const renderBox = ({
    title,
    fields,
  }: {
    title: string;
    fields: { [key: string]: Field };
  }) => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="body2">{title}</Typography>
        </Grid>
        {Object.entries(fields).map(
          ([name, { label, columns = 12, ...props }]) => {
            const columnsByType =
              typeof columns === "number"
                ? {
                    xs: columns,
                  }
                : columns;
            return (
              <Grid item key={name} {...columnsByType} mt={mt}>
                <FormField
                  label={label}
                  name={name}
                  id={name}
                  variant="outlined"
                  {...props}
                />
              </Grid>
            );
          }
        )}
      </>
    );
  };

  return (
    <Formik validationSchema={validationSchema} {...props}>
      {(props) => (
        <Grid container spacing={spacing}>
          {formBoxes.map((box) => renderBox(box))}
          <Grid item xs={12} my={3.5}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {children(props)}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default memo(Form);
