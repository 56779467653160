import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontFamily: "Roboto, sans-serif",
  h1: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  h2: {
    fontSize: 48,
    fontWeight: 600,
    color: "#23262F",
    fontFamily: "Poppins",
    lineHeight: "56px",
  },
  h5: {
    fontSize: 24,
    fontFamily: "Poppins",
    lineHeight: "32px",
    fontWeight: "600",
    color: "#23262F",
  },
  h6: {
    fontSize: 20,
    fontFamily: "Poppins",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#23262F",
  },
  body1: {
    fontSize: 14,
    fontFamily: "Poppins",
    lineHeight: "20px",
    color: "#23262F",
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: 12,
    fontFamily: "Poppins",
    lineHeight: "18px",
    color: "#777E90",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: 12,
    fontFamily: "Poppins",
    lineHeight: "18px",
    color: "#23262F",
    fontWeight: 600,
  },
  caption: {
    fontSize: 11,
    color: "#777E90",
  },
  body2: {
    fontSize: 16,
    fontFamily: "Poppins",
    lineHeight: "24px",
    color: "#23262F",
    fontWeight: 600,
  },
};

export default typography;
