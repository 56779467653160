import { Box, Divider, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useGetUser } from "@/api/user";
import CreditCard from "@/pages/Wallet/components/CreditCard";
import PurchaseHistory from "@/pages/Wallet/components/PurchaseHistory";

const Wallet = () => {
  const { user } = useGetUser();

  return (
    <Stack direction="column" width="100%">
      <Typography variant="h2" mb={8}>
        Carteira
      </Typography>
      <CreditCard />
      <Box width="100%" my={3}>
        <Divider />
      </Box>
      <PurchaseHistory />
    </Stack>
  );
};

export default memo(Wallet);
