export type MessageType =
  | string
  | object
  | Error
  | (() => Record<string, string>);

interface IInfo {
  error?: (() => IInfo) | object | Error;
  message?: string;
}

export const logMessage = (info: IInfo) => {
  const { error, message } = info;
  if (error && error instanceof Error) {
    throw error;
  } else {
    throw new Error(message);
  }
};

const normalizeMessage = (message: MessageType): IInfo => {
  if (typeof message === "function") {
    return normalizeMessage(message);
  }
  if (typeof message === "string") {
    return { message: message as string };
  }
  if (message instanceof Error) {
    const error = message;
    return { error, message: error.message };
  }
  if (
    message !== null &&
    !Array.isArray(message) &&
    typeof message === "object"
  ) {
    return message as object;
  }

  return {};
};

export const assert = (value: string | boolean, message: MessageType) => {
  if (value) {
    return value;
  }

  const info = normalizeMessage(message);
  logMessage(info);
  return null;
};
