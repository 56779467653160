import { memo, FC, ChangeEvent, useState, useEffect, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { TextField } from "@/components/TextField";
import { FormControl } from "@mui/material";

const INPUT_DELAY = 300;

interface SearchFieldProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | null;
}

const SearchField: FC<SearchFieldProps> = memo(({ value, onChange }) => {
  const [innerValue, setInnerValue] = useState<string>(value as string);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    INPUT_DELAY
  );

  useEffect(() => {
    if (value === null || value === undefined) {
      return setInnerValue("");
    }
    setInnerValue(value as string);
  }, [value]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event?.persist?.();

      setInnerValue(event?.target?.value);
      debouncedHandleOnChange(event);
    },
    [debouncedHandleOnChange]
  );

  return (
    <FormControl fullWidth>
      <TextField
        variant="outlined"
        formik={false}
        onChange={handleChange}
        placeholder="Pesquisar por nome..."
        value={innerValue}
        name="searchField"
      />
    </FormControl>
  );
});

export default memo(SearchField);
