import { useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import instance from "@/config/instance";
import { isEmpty, reject } from "lodash";
import { generatePath } from "react-router-dom";
import { ApiResponse } from "@/types/ApiResponse";
import { useSnackbar } from "@/providers/SnackbarProvider";

type AddressData = {
  address: {
    street: string | null;
    cep: string | null;
    neighborhood: string | null;
    state: string | null;
    city: string | null;
  };
};

export const ZIPCODE_API = "/zip/find/:zip";
export const useFindAddress = () => {
  const { pushSnackbar } = useSnackbar();
  const findAddressRequest = (zip: string): Promise<AddressData> =>
    instance.get(generatePath(ZIPCODE_API, { zip }));
  const findAddressMutation = useMutation<AddressData, any, string>({
    mutationKey: [ZIPCODE_API],
    mutationFn: findAddressRequest,
    onSuccess: (data) => {
      if (isEmpty(data.address)) {
        pushSnackbar({
          message:
            "Não foi possível encontrar um endereço para o CEP especificado!",
          type: "ERROR",
        });
      }
    },
  });
  const onFindAddress = (zip: string) => findAddressMutation.mutateAsync(zip);

  return {
    trigger: onFindAddress,
    ...findAddressMutation,
  };
};
