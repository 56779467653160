import { useGetStorage } from "@/api/storage";
import { ItemView } from "@/components/ItemView";
import { Box, Stack, Typography } from "@mui/material";
import { FC, memo } from "react";
import { StyledScrollable } from "../GameSettings.styles";
import { SearchField } from "@/components/SearchField";
import { Button } from "@/components/Button";
import ItemViewList from "@/components/ItemViewList/ItemViewList";

interface StorageListProps {
  accountId?: string;
}

const StorageList: FC<StorageListProps> = ({ accountId }) => {
  const { storage, isLoading } = useGetStorage(accountId);

  return (
    <Stack gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">Armazém</Typography>
        <Stack direction="row" alignItems="center">
          <SearchField value={""} onChange={() => {}} />
        </Stack>
      </Stack>
      <ItemViewList items={storage.list} isLoading={isLoading} />
    </Stack>
  );
};

export default memo(StorageList);
