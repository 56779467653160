import { ApiError } from "@/config/error";
import { queryClient } from "@/config/queryClient";
import { useSnackbar } from "@/providers/SnackbarProvider";
import { useId } from "react";

export const withErrorHandling =
  (Component: React.ComponentType) => (props: any) => {
    const { pushSnackbar } = useSnackbar();
    const id = useId();

    queryClient.getMutationCache().config.onError = (error) => {
      const apiError = error as ApiError;
      pushSnackbar({
        id,
        message: apiError?.data ? apiError.data[0].message : error.message,
        viewed: false,
        createdAt: new Date().toDateString(),
        type: "ERROR",
      });
    };

    return <Component {...props} />;
  };
