import { Box, Snackbar, styled } from "@mui/material";

export const SnackbarTitle = styled(Box)`
  font-size: 14px;
  font-weight: 600;
`;

export const SnackbarMessage = styled(Box)`
  font-size: 12px;
  color: #818181;
`;

export const StyledSnackbar = styled(Snackbar)`
  & .MuiPaper-root {
    padding: 0;
  }
  & .MuiSnackbarContent-message {
    width: 100%;
    padding: 0;
  }
`;
