import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import instance from "@/config/instance";
import { generatePath } from "react-router-dom";
import { ItemListType } from "@/types/ItemType";
import qs from "qs";

export const INVENTORY_API = "/inventory/:accountId/:charId";
export const useGetInventory = (
  accountId?: string,
  charId?: string,
  params?: { [key: string]: any }
) => {
  const queryClient = useQueryClient();
  const getInventoryItems = (): Promise<ItemListType> =>
    instance.get(
      `${generatePath(INVENTORY_API, {
        accountId: accountId!,
        charId: charId!,
      })}?${qs.stringify({
        filters: params,
      })}`
    );

  const { data, ...rest } = useQuery<ItemListType, any, ItemListType, any>({
    queryKey: [INVENTORY_API, accountId, charId, params],
    queryFn: getInventoryItems,
    structuralSharing: false,
    enabled: !!accountId && !!charId,
  });

  return useMemo(() => {
    const inventory = data ?? { list: [], count: 0 };
    const refresh = () => {
      queryClient.invalidateQueries({
        queryKey: [INVENTORY_API, accountId, charId, params],
      });
    };

    return {
      inventory,
      refresh,
      ...rest,
    };
  }, [data, accountId, charId, params, queryClient, rest]);
};
