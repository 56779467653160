import { Box, FormControl } from "@mui/material";
import { ChangeEvent, FunctionComponent, memo, useMemo } from "react";

import type {
  FilledTextFieldProps as FTFP,
  OutlinedTextFieldProps as OTFP,
  StandardTextFieldProps as STFP,
} from "@mui/material";
import { useFormikContext } from "formik";
import debounce from "lodash/debounce";
import { Field } from "./FormField.types";
import { componentTypeToComponent } from "./FormField.constants";

interface CommonTextFieldProps {
  name: string;
  loading?: boolean;
}

export type TextFieldProps =
  | (CommonTextFieldProps & STFP)
  | (CommonTextFieldProps & FTFP)
  | (CommonTextFieldProps & OTFP);

const FormField: FunctionComponent<TextFieldProps & Field> = ({
  componentType = "text",
  onChangeProp,
  ...props
}) => {
  const { values, errors, setFieldValue, initialValues } = useFormikContext();
  const { name = "" } = props;

  //@ts-expect-error
  const hasError = values[name] !== initialValues[name] && errors[name];

  const errorMessage =
    //@ts-expect-error
    values[name] !== initialValues[name] ? errors[name] : undefined;

  const fieldProps = useMemo(() => {
    switch (componentType) {
      default:
        return {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(name, e.target.value);
          },
          defaultValue: values?.[name as keyof typeof values],
        };
    }
  }, [componentType, name, onChangeProp, setFieldValue, values]);

  const Component = componentTypeToComponent[componentType];

  return (
    <Box>
      <FormControl fullWidth>
        <Component
          variant="outlined"
          fullWidth
          error={hasError}
          helperText={errorMessage}
          {...props}
          {...fieldProps}
        />
      </FormControl>
    </Box>
  );
};

export default memo(FormField);
