import { FC, memo, useMemo } from "react";
import { Box, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import { ItemType } from "@/types/ItemType";
import { getItemName, parseDescription } from "@/utils/item.utils";
import { formatNumberWithThousandSeparator } from "@/utils/numberFormat.utils";
import { GiMightySpanner } from "react-icons/gi";
import { GiShatteredGlass } from "react-icons/gi";
import { GiChestArmor } from "react-icons/gi";

import Cards from "./components/Cards";
import { EnchantGradeMap } from "@/constants/enchantGrade.constants";
import { some } from "lodash";
import { Trans } from "react-i18next";
import { getAssetUrl } from "@/utils/assets.utils";
import { t } from "i18next";

interface ItemViewProps {
  item?: ItemType;
}

const ALLOWED_CARDS_ROW = ["Armor", "Weapon", "Shadowgear"];

const ItemRow: FC<ItemViewProps> = ({ item }) => {
  if (!item) {
    return null;
  }
  const itemName = getItemName({ item, showSlots: false });
  const description = item?.description;

  const descriptionText = useMemo(() => {
    return item.description.descriptionName.map((line, index) => (
      <Box padding={0.5}>
        <Typography variant="subtitle1" key={index}>
          {parseDescription(line)}
        </Typography>
      </Box>
    ));
  }, [description]);

  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="space-between"
      sx={{
        padding: "16px 12px",
        width: 175,
        minWidth: 175,
        height: 230,
        border: "2px solid #f7f7f7",
        borderRadius: "8px",
        position: "relative",
        overflow: "hidden",
        boxShadow: "0px 0px 10px 0px #8a8a8a21",
      }}
    >
      <Box
        position="absolute"
        sx={{
          top: "calc(50% - 20px)",
          transform: "translateY(-50%)",
        }}
      >
        <Tooltip
          title={
            //@ts-expect-error
            <Box component={Trans} p={1} transWrapTextNodes>
              {descriptionText}
            </Box>
          }
        >
          <img
            src={getAssetUrl(
              t("assets.item_collection", {
                resourceName: description.resourceName,
              })
            )}
          />
        </Tooltip>
      </Box>
      {item.refine > 0 && (
        <Box
          sx={{
            background: "#23262F",
            position: "absolute",
            top: 0,
            width: "40px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0 0 8px 8px",
            boxShadow: "0px 6px 20px 0px #8a8a8a21",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "white",
            }}
          >
            +{item.refine}
          </Typography>
        </Box>
      )}

      {item.refine > 0 && (
        <Box
          sx={{
            background: "#23262F",
            position: "absolute",
            top: 0,
            width: "40px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0 0 8px 8px",
            boxShadow: "0px 6px 20px 0px #8a8a8a21",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "white",
            }}
          >
            +{item.refine}
          </Typography>
        </Box>
      )}

      {item.amount > 1 && (
        <Box
          sx={{
            background: "#E6E8EC",
            position: "absolute",
            top: 0,
            right: 0,
            padding: "0 8px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0 0 0 8px",
            boxShadow: "0px 6px 20px 0px #8a8a8a21",
          }}
        >
          <Typography variant="body1">
            {formatNumberWithThousandSeparator(item.amount.toString(), ".")}x
          </Typography>
        </Box>
      )}
      <Stack
        gap={1}
        sx={{
          width: "100%",
          padding: "8px",
          position: "absolute",
          bottom: "0",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          px={1}
          alignItems="flex-end"
          width="100%"
        >
          <Cards
            cards={item.cards}
            slots={item.slots}
            visible={ALLOWED_CARDS_ROW.includes(item.type)}
          />
          <Stack direction="column" gap={0.5}>
            <Box
              borderRadius={50}
              sx={{
                width: 24,
                height: 24,
                minWidth: 24,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: EnchantGradeMap[item.enchantgrade].color,
              }}
            >
              <Box
                fontSize={14}
                color="white"
                sx={{
                  textShadow: "0 0 4px #787878",
                }}
              >
                {EnchantGradeMap[item.enchantgrade].name}
              </Box>
            </Box>
            {item.equip > 0 && (
              <Tooltip
                title={
                  <Typography variant="subtitle1">Item equipado</Typography>
                }
              >
                <Box
                  borderRadius={50}
                  sx={{
                    width: 24,
                    height: 24,
                    minWidth: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#000000",
                  }}
                >
                  <Box component={GiChestArmor} fontSize={16} color="white" />
                </Box>
              </Tooltip>
            )}
            <Tooltip
              title={
                <Typography variant="subtitle1">
                  Equipamento quebrado
                </Typography>
              }
            >
              <Box
                borderRadius={50}
                sx={{
                  width: 24,
                  height: 24,
                  minWidth: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#f05454",
                }}
              >
                <Box component={GiShatteredGlass} fontSize={16} color="white" />
              </Box>
            </Tooltip>
            {some(item.options, ["id", Boolean]) && (
              <Tooltip
                title={
                  <Typography variant="subtitle1">
                    Contém encantamentos
                  </Typography>
                }
              >
                <Box
                  borderRadius={50}
                  sx={{
                    width: 24,
                    height: 24,
                    minWidth: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#23262F",
                  }}
                >
                  <Box
                    component={GiMightySpanner}
                    fontSize={16}
                    color="white"
                  />
                </Box>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: `0px -6px 20px 0px ${
              EnchantGradeMap[item.enchantgrade].color
            }90`,
            height: 40,
            border: `2px solid ${EnchantGradeMap[item.enchantgrade].color}40`,
            borderRadius: "8px 8px",
          }}
        >
          <Typography
            align="center"
            variant="subtitle1"
            fontSize={10}
            sx={{
              lineHeight: 1.2,
              width: "80%",
            }}
          >
            {itemName}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default memo(ItemRow);
