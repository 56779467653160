import * as yup from "yup";

// Validação customizada para CPF
export const cpfValidation = yup.string().matches(/^\d{11}$/, {
  message: "Número de CPF inválido",
  excludeEmptyString: true,
});

// Validação customizada para telefone
export const phoneValidation = yup.string().matches(/^\d{11}$/, {
  message: "Número de telefone inválido",
  excludeEmptyString: true,
});
