import { FC, memo } from "react";
import {
  StyledCharBox,
  CharContentBox,
  CharInnerBox,
  CharStatusBox,
  CharClassBox,
  CharName,
} from "../CharView.styles";
import CharImage from "./CharImage";
import { CharData } from "@/api/char";
import { useQueryParams } from "@/hooks/useQueryParams";
import { getAssetUrl } from "@/utils/assets.utils";
import { t } from "i18next";

interface CharInfoProps {
  char: CharData;
}

const CharInfo: FC<CharInfoProps> = ({ char }) => {
  const { online, sex, name, _class, charId } = char;
  const isOnline = !!online;
  const suffix = sex === "M" ? "_³²" : "¿©";
  const { getQueryParam, setQueryParam } = useQueryParams();
  const selectedChar = getQueryParam<string>("charId");
  const isSelected = Number(selectedChar) === charId;

  const handleSelectChar = () => {
    setQueryParam({
      charId: charId.toString(),
    });
  };

  return (
    <StyledCharBox onClick={handleSelectChar}>
      <CharContentBox isSelected={isSelected}>
        <CharInnerBox>
          <CharStatusBox isOnline={isOnline} />
          <CharClassBox>
            <img src={getAssetUrl(t("assets.char_class", { class: _class }))} />
          </CharClassBox>
          <CharImage char={char} suffix={suffix} />
        </CharInnerBox>
        <CharName sx={{ color: "black" }}>{name}</CharName>
      </CharContentBox>
    </StyledCharBox>
  );
};

export default memo(CharInfo);
