import GameSettings from "@/pages/GameSettings/GameSettings";
import Notifications from "@/pages/Notifications/Notifications";
import ProfileSettings from "@/pages/ProfileSettings/ProfileSettings";
import Security from "@/pages/Security/Security";
import Wallet from "@/pages/Wallet/Wallet";
import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

const Login = lazy(() => import("@/pages/Login/Login"));
const AccountsLayout = lazy(() => import("@/layouts/AccountsLayout"));

export const ROUTES: { [key: string]: any } = {
  homepage: "/",
  login: "/login",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  settings: "/settings",
  profileSettings: "/settings/profile",
  gameSettings: "/settings/game",
  security: "/settings/security",
  notifications: "/settings/notifications",
  wallet: "/settings/wallet",
};

export const router = createBrowserRouter([
  {
    path: ROUTES.homepage,
    element: <>Home</>,
  },
  {
    path: ROUTES.settings,
    element: <AccountsLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.profileSettings} />,
      },
      {
        path: ROUTES.profileSettings,
        element: <ProfileSettings />,
      },
      {
        path: ROUTES.gameSettings,
        element: <GameSettings />,
      },
      {
        path: ROUTES.security,
        element: <Security />,
      },
      {
        path: ROUTES.notifications,
        element: <Notifications />,
      },
      {
        path: ROUTES.wallet,
        element: <Wallet />,
      },
    ],
  },
  //   {
  //     path: ROUTES.market,
  //     element: <MainLayout />,
  //     children: [
  //       {
  //         index: true,
  //         element: <Market />,
  //       },
  //     ],
  //   },
  {
    path: ROUTES.login,
    element: <Login />,
  },
  //   {
  //     path: ROUTES.news,
  //     element: <Article />,
  //   },
]);
