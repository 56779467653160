import { Theme, tooltipClasses } from "@mui/material";

export const tooltipStyle: Partial<Theme["components"]> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        background: "white",
        boxShadow: "0px 16px 20px 0px #0000001c",
        padding: 16,
        borderRadius: 8,
      },
    },
  },
};
