import { Stack, styled } from "@mui/material";

export const StyledScrollable = styled(Stack)`
  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #777e90;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #23262f;
  }
`;
