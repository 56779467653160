import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export const formatDate = (
  date: number | string,
  dateFormat: string
): string => {
  return format(new Date(date), dateFormat, { locale: ptBR });
};

// Formatos comuns de data em PT-BR
export const formatDateShort = (date: number | string): string => {
  return formatDate(date, "dd/MM/yyyy");
};

export const formatDateLong = (date: number | string): string => {
  return formatDate(date, "dd 'de' MMMM 'de' yyyy");
};

export const formatDateTime = (date: number | string): string => {
  return formatDate(date, "dd/MM/yyyy 'às' HH:mm");
};

export const formatDateTimeLong = (date: number | string): string => {
  return formatDate(date, "dd 'de' MMMM 'de' yyyy 'às' HH:mm");
};

export const formatTime = (date: number | string): string => {
  return formatDate(date, "HH:mm:ss");
};
