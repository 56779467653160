import { Box, CircularProgress } from "@mui/material";
import { FC, memo, useEffect, useRef } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import { useFormikContext } from "formik";
import instance from "@/config/instance";
import { useFindAddress } from "@/api/address";
import { Button } from "@/components/Button";
import { useGetUser } from "@/api/user";

interface FindCEPProps {}

const FindCEP: FC<FindCEPProps> = () => {
  const { values, setValues, setFieldError } = useFormikContext<any>();
  const { trigger, data, isPending } = useFindAddress();
  const zip = values?.addressZipcode;
  const { user } = useGetUser();
  const isValidZip = zip?.length === 8;

  const handleSearch = async () => {
    trigger(zip);
  };

  useEffect(() => {
    if (data && !isPending) {
      setValues({
        ...values,
        addressLine1: data.address.street,
        addressState: data.address.state,
        addressCity: data.address.city,
        addressNeighborhood: data.address.neighborhood,
      });

      setFieldError(
        "addressZipcode",
        data.address.street ? undefined : "CEP não encontrado!"
      );
    }
  }, [data]);

  useEffect(() => {
    const isNewZip = user?.addressZipcode !== zip;
    setValues({
      ...values,
      addressLine1: isNewZip ? "" : user?.addressLine1,
      addressState: isNewZip ? "" : user?.addressState,
      addressCity: isNewZip ? "" : user?.addressCity,
      addressNumber: isNewZip ? "" : user?.addressNumber,
      addressNeighborhood: isNewZip ? "" : user?.addressNeighborhood,
    });
  }, [values.addressZipcode]);

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleSearch}
      isLoading={isPending}
      disabled={!isValidZip}
    >
      Buscar
    </Button>
  );
};

export default memo(FindCEP);
