import { Box, Stack, styled } from "@mui/material";

export const StyledCharBox = styled(Box)`
  cursor: pointer;
  border-radius: 8px;
  background: white;
  position: relative;
  box-shadow: 0px 0px 11px 3px #8a8a8a2b;
`;

export const StyledNewChar = styled(Box)`
  width: 155px;
  height: 192px;
  border-radius: 8px;
  padding: 6px;
  transition: all 0.3s ease;
  color: #e6e8ec;
  border: 2px dashed #e6e8ec;
  &:hover {
    color: #23262f;
    border-color: #23262f;
  }
`;

export const StyledNewCharButton = styled(Stack)`
  font-size: 12px;
`;

export const CharContentBox = styled(Box)<{ isSelected: boolean }>`
  width: 155px;
  height: 192px;
  border-radius: 8px;
  padding: 6px;
  transition: all 0.3s ease;
  background: white;
  border: 2px solid
    ${({ isSelected }) => (isSelected ? "#d8d8d8 !important" : "transparent")};
  &:hover {
    border-color: #e6e8ec;
  }
`;

export const CharInnerBox = styled(Box)`
  width: 100%;
  height: 152px;
  border-radius: 8px;
  position: relative;
  transition: background 0.3s ease;
  background: white;
`;

export const CharStatusBox = styled(Box)<{ isOnline: boolean }>`
  width: 10px;
  height: 10px;
  left: 10px;
  top: 10px;
  border-radius: 50%;
  position: absolute;
  transition: background 0.3s ease;
  background: ${({ isOnline }) => (isOnline ? "green" : "red")};
`;

export const CharClassBox = styled(Box)`
  right: 7px;
  top: 7px;
  position: absolute;
`;

export const CharName = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 600;
  font-family: system-ui;
  color: black;
`;
