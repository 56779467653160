export const EnchantGradeMap: Record<number, any> = {
  "0": {
    name: "D",
    color: "#c8c8c8",
  },
  "1": {
    name: "C",
    color: "#99dfb0",
  },
  "2": {
    name: "B",
    color: "#99b8df",
  },
  "3": {
    name: "A",
    color: "#c299df",
  },
  "4": {
    name: "S",
    color: "#fff283",
  },
};
