import { UserData } from "@/api/user";
import CPFValidation from "@/components/CPFValidation/CPFValidation";
import { Field } from "@/components/FormField/FormField.types";
import { cpfValidation, phoneValidation } from "@/utils/validations";
import { parseISO } from "date-fns";
import * as yup from "yup";

const accountInfoFields: { [key: string]: Field } = {
  fullName: {
    columns: 6,
    label: "Nome Completo",
    helperText: "Insira seu nome como aparece no seu documento de Identidade.",
  },
  displayName: {
    columns: 6,
    label: "Nome de Exibição",
  },
  identity: {
    columns: 6,
    label: "CPF",
    componentType: "number",
    isCPF: true,
  },
  email: {
    columns: 6,
    label: "Email",
    disabled: true,
    helperText: "Endereço de email vinculado ao Gmail. Não pode ser alterado.",
  },
  phone: {
    columns: 6,
    label: "Telefone",
    componentType: "number",
    isPhone: true,
  },
  birthdate: {
    columns: 6,
    label: "Data de Nascimento",
    componentType: "date",
    helperText:
      "Apenas usuários maiores de 18 anos podem habilitar as funcionalidades RMT.",
  },
};

const validationSchema = {
  fullName: yup.string(),
  displayName: yup.string().required("Nome de exibição é obrigatório"),
  identity: cpfValidation,
  phone: phoneValidation,
  birthdate: yup.string().nullable(),
};

export const useInitializeAccountInfoForm = (userData: UserData) => {
  accountInfoFields.identity.action = userData?.identity ? (
    <CPFValidation isValidated={userData?.identityVerified} />
  ) : undefined;

  accountInfoFields.identity.disabled = userData?.identityVerified;
  accountInfoFields.fullName.disabled = userData?.identityVerified;

  return {
    fields: accountInfoFields,
    initialValues: {
      displayName: userData?.displayName || "",
      fullName: userData?.fullName || "",
      email: userData?.email || "",
      identity: userData?.identity || "",
      phone: userData?.phone || "",
      birthdate: userData?.birthdate ? parseISO(userData?.birthdate) : null,
    },
    validationSchema,
  };
};
