export enum CardPrefix {
  "id_4001" = "da Fortuna",
  "id_4002" = "Vital",
  "id_4003" = "Resistente",
  "id_4004" = "Expediente",
  "id_4005" = "Celestial",
  "id_4006" = "Talismã",
  "id_4007" = "Espectral",
  "id_4008" = "Extraforte",
  "id_4009" = "Leves",
  "id_4010" = "Sagaz",
  "id_4011" = "Eficaz",
  "id_4012" = "Tenaz",
  "id_4013" = "Excelente",
  "id_4014" = "da Disciplina",
  "id_4015" = "do Vento",
  "id_4016" = "Veloz",
  "id_4017" = "Estonteante",
  "id_4018" = "Consciente",
  "id_4019" = "Tonificante",
  "id_4020" = "Cegante",
  "id_4021" = "Hábil",
  "id_4022" = "do Vigor",
  "id_4023" = "Mental",
  "id_4024" = "Nocauteante",
  "id_4025" = "Fatal",
  "id_4026" = "Acelerante",
  "id_4027" = "Ágil",
  "id_4028" = "do Lutador",
  "id_4029" = "Feroz",
  "id_4030" = "Lunar",
  "id_4031" = "de Ares",
  "id_4032" = "de Atena",
  "id_4033" = "Desintoxicante",
  "id_4034" = "Pontual",
  "id_4035" = "Capital",
  "id_4036" = "Possante",
  "id_4037" = "Inoculante",
  "id_4038" = "Saudáveis",
  "id_4039" = "do Filósofo",
  "id_4040" = "de Teleporte",
  "id_4041" = "do Insone",
  "id_4042" = "do Defensor",
  "id_4043" = "Excepcional",
  "id_4044" = "do Esconderijo",
  "id_4045" = "Resiliente",
  "id_4046" = "do Clérigo",
  "id_4047" = "Intangível",
  "id_4048" = "de Veneno",
  "id_4049" = "Inflamável",
  "id_4050" = "de Hermes",
  "id_4051" = "Lábil",
  "id_4052" = "Brilhante",
  "id_4053" = "de Cura",
  "id_4054" = "Celeste",
  "id_4055" = "Congelante",
  "id_4056" = "da Tempestade",
  "id_4057" = "Silenciante",
  "id_4058" = "de Prometeu",
  "id_4059" = "de Hera",
  "id_4060" = "Bestial",
  "id_4061" = "Imortal",
  "id_4062" = "Neutralizante",
  "id_4063" = "Imunizante",
  "id_4064" = "Integral",
  "id_4065" = "Fundamental",
  "id_4066" = "de Artemis",
  "id_4067" = "Energizante",
  "id_4068" = "Termal",
  "id_4069" = "Desidratante",
  "id_4070" = "de Wicca",
  "id_4071" = "dos Anjos",
  "id_4072" = "Indestrutível",
  "id_4073" = "do Vendedor",
  "id_4074" = "de Urtiga",
  "id_4075" = "Hipnotizante",
  "id_4076" = "Amaldiçoante",
  "id_4077" = "de Concentração",
  "id_4078" = "de Regeneração",
  "id_4079" = "Descomunal",
  "id_4080" = "Estival",
  "id_4081" = "da Montanha",
  "id_4082" = "Gigante",
  "id_4083" = "de Alga",
  "id_4084" = "da Explosão",
  "id_4085" = "Abissal",
  "id_4086" = "Letal",
  "id_4087" = "do Juiz",
  "id_4088" = "do Ilusionista",
  "id_4089" = "de Poseidon",
  "id_4090" = "Escarlate",
  "id_4091" = "de Contra-ataque",
  "id_4092" = "Primordial",
  "id_4093" = "Medicinal",
  "id_4094" = "Ciclonal",
  "id_4095" = "do Oceano",
  "id_4096" = "Feral",
  "id_4097" = "Verdes",
  "id_4098" = "de Zefir",
  "id_4099" = "de Hefestus",
  "id_4100" = "de Fada",
  "id_4101" = "de Gaia",
  "id_4102" = "de Loki",
  "id_4103" = "Clarividente",
  "id_4104" = "Caotizante",
  "id_4105" = "Anti-congelante",
  "id_4106" = "Ancestral",
  "id_4107" = "Essenciais",
  "id_4108" = "da Nascente",
  "id_4109" = "do Deserto",
  "id_4110" = "da Vacina",
  "id_4111" = "Exorcisante",
  "id_4112" = "do Arauto",
  "id_4113" = "da Alma",
  "id_4114" = "de Apolo",
  "id_4115" = "Cabal",
  "id_4116" = "da Luz",
  "id_4117" = "do Duelista",
  "id_4118" = "Nobre",
  "id_4119" = "do Mal",
  "id_4120" = "do Dragão",
  "id_4121" = "Magistral",
  "id_4122" = "do Sentinela",
  "id_4123" = "de Heimdall",
  "id_4124" = "de Perseu",
  "id_4125" = "Hibernal",
  "id_4126" = "Colossal",
  "id_4127" = "do Vigilante",
  "id_4128" = "do Escaravelho",
  "id_4129" = "da Noite",
  "id_4130" = "Solar",
  "id_4131" = "Fugazes",
  "id_4132" = "de Jóias",
  "id_4133" = "de Balder",
  "id_4134" = "Espiritual",
  "id_4135" = "de Espelhos",
  "id_4136" = "do Inferno",
  "id_4137" = "do Infinito",
  "id_4138" = "do Paraíso",
  "id_4139" = "do Luar",
  "id_4140" = "Rebelde",
  "id_4141" = "de Hades",
  "id_4142" = "da Fúria",
  "id_4143" = "do Herói",
  "id_4144" = "da Eternidade",
  "id_4145" = "do Ventríloquo",
  "id_4146" = "de Reflexão",
  "id_4147" = "do Ceifeiro",
  "id_4148" = "do Místico",
  "id_4149" = "do Céu Limpo",
  "id_4150" = "da Resistência à Magia",
  "id_4151" = "do Destruidor da Natureza",
  "id_4152" = "Suculenta",
  "id_4153" = "de Câncer",
  "id_4154" = "Aromático",
  "id_4155" = "Goblin Fatal",
  "id_4156" = "Chama Fatal",
  "id_4157" = "Fuminante",
  "id_4158" = "da Chacina",
  "id_4159" = "do Nível Alto",
  "id_4160" = "do Nível Alto",
  "id_4161" = "Contra Magia",
  "id_4162" = "do Cego",
  "id_4163" = "Selvagem",
  "id_4164" = "do Pescador",
  "id_4165" = "do Inseto Sugador",
  "id_4166" = "do Azar",
  "id_4167" = "Bestiano",
  "id_4168" = "da Perdição",
  "id_4169" = "do Mestre Encantador",
  "id_4170" = "do Endurecimento",
  "id_4171" = "Drenador de Almas",
  "id_4172" = "Cortante",
  "id_4173" = "do Hipnotismo",
  "id_4174" = "Maravilhoso",
  "id_4175" = "Empurrador",
  "id_4176" = "Serpente Fatal",
  "id_4177" = "da Pedra Verde",
  "id_4178" = "do Mestre Arqueiro",
  "id_4179" = "da Luz Intensa",
  "id_4180" = "Draconiano",
  "id_4181" = "do Silêncio Mortal",
  "id_4182" = "Demoníaco",
  "id_4183" = "do Lobisomem",
  "id_4184" = "da Parada",
  "id_4185" = "da Sabedoria",
  "id_4186" = "Musculoso",
  "id_4187" = "da Noite Brilhante",
  "id_4188" = "da Pedra Vermelha",
  "id_4189" = "da Miséria",
  "id_4190" = "do Animador",
  "id_4191" = "da Linha da Vida",
  "id_4192" = "dos Peixes Fatais",
  "id_4193" = "da Paciência",
  "id_4194" = "do Atordoamento",
  "id_4195" = "da Pedra Azul",
  "id_4196" = "do Jellopy",
  "id_4197" = "do Sortudo",
  "id_4198" = "da Mira Verdadeira",
  "id_4199" = "do Encontrão",
  "id_4200" = "da Resistência à Poder Mágico",
  "id_4201" = "Múltiplo Atordoamento",
  "id_4202" = "Exorcismo",
  "id_4203" = "Flamejante",
  "id_4204" = "do Ganhador",
  "id_4205" = "do Cubo Azul",
  "id_4206" = "Cúbico",
  "id_4207" = "Contra o Menor",
  "id_4208" = "da Energia Plena",
  "id_4209" = "da Rachadura",
  "id_4210" = "Pressão",
  "id_4211" = "do Especialista",
  "id_4212" = "do Empurrão",
  "id_4213" = "do Silêncio",
  "id_4214" = "Canibal",
  "id_4215" = "do Alimento",
  "id_4216" = "do Sopro Gelado",
  "id_4217" = "Auto Cura",
  "id_4218" = "do Pesadelo",
  "id_4219" = "da Pedra Mágica",
  "id_4220" = "Fiel",
  "id_4221" = "do Colecionador de Insetos",
  "id_4222" = "do Sono",
  "id_4223" = "Resistente ao Atordoamento",
  "id_4224" = "do Dia Chuvoso",
  "id_4225" = "Atirador",
  "id_4226" = "Nível Alto",
  "id_4227" = "do Cheiro de Carne",
  "id_4228" = "do Dia",
  "id_4229" = "do Gerente",
  "id_4230" = "do Covarde",
  "id_4231" = "Encontrão",
  "id_4232" = "Refrescante",
  "id_4233" = "Sorte do Petit",
  "id_4234" = "do Espelho Inoxidável",
  "id_4235" = "do Anjo Corrupto",
  "id_4236" = "da Proteção",
  "id_4237" = "da Ira dos Deuses",
  "id_4238" = "do Poder Duplo",
  "id_4239" = "do Caçador Veterano",
  "id_4240" = "da Resistência à Magia",
  "id_4241" = "Duplo 7 Sortudo",
  "id_4242" = "do Apocalipse",
  "id_4243" = "da Escapada",
  "id_4244" = "do Seguidor",
  "id_4245" = "do Assassino",
  "id_4246" = "Morte",
  "id_4247" = "Peixe-Estrela",
  "id_4248" = "Cruz Brilhante",
  "id_4249" = "do Exorcista",
  "id_4250" = "Contra Grandes",
  "id_4251" = "Invasão",
  "id_4252" = "do Trevo de 4 Folhas",
  "id_4253" = "da Guarda Real",
  "id_4254" = "Contra Médios",
  "id_4255" = "Esmagador",
  "id_4256" = "Desejado",
  "id_4257" = "Alado",
  "id_4258" = "Inteligência Melhorada",
  "id_4259" = "da Natureza",
  "id_4260" = "Protetor Contra Confusão",
  "id_4261" = "Protetor Contra Cegueira",
  "id_4262" = "do Dia Nublado",
  "id_4263" = "Desejo Sangrento",
  "id_4264" = "Relâmpago",
  "id_4265" = "do Alvorecer",
  "id_4266" = "Resistência Mágica",
  "id_4267" = "do Caçador de Dragões",
  "id_4268" = "Extensor",
  "id_4269" = "do Pesadelo",
  "id_4270" = "do Veneno",
  "id_4271" = "da Pedra Amarela",
  "id_4272" = "da Rapidez",
  "id_4273" = "Concha",
  "id_4274" = "Morto-Vivo Sugador",
  "id_4275" = "do Morto-Vivo Matador",
  "id_4276" = "Crise",
  "id_4277" = "do Mestre Atirador",
  "id_4278" = "da Resistência à Magia",
  "id_4279" = "do Colecionador de Almas",
  "id_4280" = "da Benção Grandiosa",
  "id_4281" = "Trabalhador",
  "id_4282" = "da Poção",
  "id_4283" = "Lança de Mana",
  "id_4284" = "Toque Suave",
  "id_4285" = "Conjurado por Elfo",
  "id_4286" = "do Pânico",
  "id_4287" = "da Resistência à Magia",
  "id_4288" = "Nível Alto",
  "id_4289" = "Planta Sugadora",
  "id_4290" = "Reflexo",
  "id_4291" = "Kobold Fatal",
  "id_4292" = "Planta Fatal",
  "id_4293" = "Chance",
  "id_4294" = "do Astuto",
  "id_4295" = "do Apagão",
  "id_4296" = "Cobiça",
  "id_4297" = "Certeiro",
  "id_4298" = "do Caos",
  "id_4299" = "Guardião",
  "id_4300" = "da Carga Venenosa",
  "id_4301" = "do Sangramento",
  "id_4302" = "do Alto Risco",
  "id_4303" = "Místico",
  "id_4304" = "do Mestre Escudeiro",
  "id_4305" = "Explosão",
  "id_4306" = "do Rei",
  "id_4307" = "Força-Aquática Ganha",
  "id_4308" = "da Drenagem de Força",
  "id_4309" = "Cobertura Rígida",
  "id_4310" = "da Maldade Fatal",
  "id_4311" = "Contra Poder Maligno",
  "id_4312" = "da Alma",
  "id_4313" = "do Pântano",
  "id_4314" = "da Guarda Flamejante",
  "id_4315" = "da Pedra Amaldiçoada",
  "id_4316" = "Ganho da Alma Angelical",
  "id_4317" = "Aura",
  "id_4318" = "Causador de Tempestade",
  "id_4319" = "do Mestre Espadachim",
  "id_4320" = "Luz do Meteoro",
  "id_4321" = "do Polimorfo",
  "id_4322" = "do Contra-Ataque",
  "id_4323" = "Congelante",
  "id_4324" = "do Ártico",
  "id_4325" = "da Dureza",
  "id_4326" = "do Cheiro de Peixe",
  "id_4327" = "do Fluxo Mágico",
  "id_4328" = "do Hiper",
  "id_4329" = "do Ganho da Alma",
  "id_4330" = "do Imune a Maldição",
  "id_4331" = "da Evasão",
  "id_4332" = "de Hermod",
  "id_4333" = "de Jormungard",
  "id_4334" = "do Antiatirador",
  "id_4335" = "do Gnomo",
  "id_4336" = "da Vitalidade",
  "id_4337" = "da Agressão",
  "id_4338" = "da Troca de Vitalidade",
  "id_4339" = "da Muralha",
  "id_4340" = "de Hel",
  "id_4341" = "do Desarmamento",
  "id_4342" = "do Mamute",
  "id_4343" = "de Frigga",
  "id_4344" = "da Refeição",
  "id_4345" = "da Sílfide",
  "id_4346" = "da Troca de Força",
  "id_4347" = "do Pescador",
  "id_4348" = "de Fenrir",
  "id_4349" = "Antiflecha",
  "id_4350" = "da Ondina",
  "id_4351" = "da Baixa Esquiva",
  "id_4352" = "da Juventude",
  "id_4353" = "da Yggdrasil",
  "id_4354" = "da Estabilidade",
  "id_4355" = "do Açougueiro",
  "id_4356" = "da Autoridade",
  "id_4357" = "do Frenesi",
  "id_4358" = "da Apatia",
  "id_4359" = "das Sombras",
  "id_4360" = "do Mercenário",
  "id_4361" = "do Hollgrehenn",
  "id_4362" = "do Acerto",
  "id_4363" = "do Arcanjo",
  "id_4364" = "do Selo Mágico",
  "id_4365" = "da Tormenta",
  "id_4366" = "da Conjuração",
  "id_4367" = "da Pressão",
  "id_4368" = "Rápido",
  "id_4369" = "da Troca de Sorte",
  "id_4370" = "do Enfraquecimento",
  "id_4371" = "de Forseti",
  "id_4372" = "da Medicina",
  "id_4373" = "da Maldição",
  "id_4374" = "da Visão",
  "id_4375" = "do Nível Elevado",
  "id_4376" = "da Magia Plena",
  "id_4377" = "da Sobremesa",
  "id_4378" = "da Baixa Força",
  "id_4379" = "da Baixa Magia",
  "id_4380" = "da Salamandra",
  "id_4381" = "de Bragi",
  "id_4382" = "de Mímir",
  "id_4383" = "do Caos",
  "id_4384" = "do Tormento",
  "id_4385" = "da Bebida",
  "id_4386" = "do Abrigo Mágico",
  "id_4387" = "da Troca de Agilidade",
  "id_4388" = "da Alma",
  "id_4389" = "Elemental",
  "id_4390" = "do Sangramento",
  "id_4391" = "do Lanche",
  "id_4392" = "da Troca de Destreza",
  "id_4393" = "da Troca de Inteligência",
  "id_4394" = "da Punição Divina",
  "id_4395" = "do Verme",
  "id_4396" = "do Entorpecer",
  "id_4397" = "da Amnésia",
  "id_4398" = "da Escuridão",
  "id_4399" = "do Impacto",
  "id_4400" = "da Tentação",
  "id_4401" = "da Esquiva",
  "id_4402" = "da Escolha",
  "id_4403" = "da Pós-conjuração",
  "id_4404" = "da Hemorragia",
  "id_4405" = "do Reflexo",
  "id_4406" = "Cintilante",
  "id_4407" = "de Freya",
  "id_4408" = "do Abissal",
  "id_4409" = "da Histeria",
  "id_4410" = "da Demência",
  "id_4411" = "de Arta",
  "id_4412" = "de Aria",
  "id_4413" = "da Resistência",
  "id_4414" = "da Górgona",
  "id_4415" = "Gentil",
  "id_4416" = "de Yana",
  "id_4417" = "de Flaw",
  "id_4418" = "de Hail",
  "id_4419" = "do Jack Frost",
  "id_4420" = "do Conforto",
  "id_4421" = "do Chinook",
  "id_4422" = "da Maresia",
  "id_4423" = "de Sharia",
  "id_4424" = "de Pedra",
  "id_4425" = "Bestial",
  "id_4426" = "da Excelência",
  "id_4427" = "do Poder",
  "id_4428" = "do Feixe",
  "id_4429" = "do Nessus",
  "id_4430" = "Infernal",
  "id_4431" = "Ínfera",
  "id_4432" = "Vulcanizada",
  "id_4433" = "de Ignis",
  "id_4434" = "do Mineiro",
  "id_4435" = "do Ultraje",
  "id_4436" = "do Lunático",
  "id_4437" = "do Fanático",
  "id_4438" = "Intelectual",
  "id_4439" = "do Maldito",
  "id_4440" = "da Indiferença",
  "id_4441" = "do Arrogante",
  "id_4442" = "da Terra Furiosa",
  "id_4443" = "do Gelo Perfurante",
  "id_4444" = "do Fogo Eterno",
  "id_4445" = "do Vento Uivante",
  "id_4447" = "do Envenenamento",
  "id_4448" = "do Amaldiçoado",
  "id_4449" = "do Abençoado",
  "id_4450" = "do Mestre Feiticeiro",
  "id_4451" = "do Axis",
  "id_4452" = "do Feiticeiro",
  "id_4453" = "da Violência",
  "id_4454" = "Ceifadeiro",
  "id_4455" = "Feya",
  "id_4456" = "do Arcano",
  "id_4457" = "Psíquica",
  "id_4458" = "da Ilusão",
  "id_4459" = "do Conjurador",
  "id_4460" = "do Curandeiro",
  "id_4461" = "da Forma",
  "id_4462" = "da Defesa Prima",
  "id_4463" = "do Ataque Primo",
  "id_4464" = "do Ataque Crítico",
  "id_4465" = "da Espada Leve",
  "id_4466" = "do Tiro Preciso",
  "id_4467" = "Pesado",
  "id_4468" = "Hera Venenosa",
  "id_4469" = "Antipeixe",
  "id_4470" = "Antibotanica",
  "id_4471" = "Antidragão",
  "id_4472" = "Antifera",
  "id_4473" = "Antizumbi",
  "id_4474" = "Mortal",
  "id_4475" = "Antidisforme",
  "id_4476" = "Anti-inseto",
  "id_4477" = "Antidemoniaca",
  "id_4480" = "da Recarga Selada",
  "id_4481" = "do Jack Frost Selado",
  "id_4482" = "da Juventude Selada",
  "id_4483" = "Espiritual Selada",
  "id_4484" = "de Jóias Seladas",
  "id_4485" = "do Abissal Selado",
  "id_4478" = "Estranha Verdade",
  "id_4479" = "Estranha Mentira",
  "id_4491" = "da Pressão Selada",
  "id_4494" = "Explosão Selada",
  "id_4495" = "da Proteção Selada",
  "id_4497" = "Causador de Tempestade Selada",
  "id_4498" = "da Magia Plena Selada",
  "id_4499" = "Desejo Sangrento Selado",
  "id_4500" = "de Espelhos Selados",
  "id_4501" = "do Arcanjo Selado",
  "id_4502" = "do Hollgrehenn Selada",
  "id_4503" = "da Visão Selada",
  "id_4504" = "de Heimdall Selada",
  "id_4486" = "do Ventríloquo Selada",
  //*****************************************************
  //------------Aplicar em 2015-01-13--------------------
  //*****************************************************
  "id_4505" = "da Bruxa Má",
  "id_4506" = "do Arqueiro",
  "id_4507" = "Scaraba",
  "id_4508" = "do Enlouquecido",
  "id_4509" = "Contra Insetos",
  "id_4510" = "do Blecaute",
  "id_4511" = "Silentium",
  "id_4512" = "do Noviço",
  "id_4513" = "do Gatuno",
  "id_4514" = "Exorcista",
  "id_4515" = "Flora",
  "id_4516" = "da Parede de Pedra",
  "id_4517" = "do Mago",
  "id_4518" = "em Chamas",
  "id_4519" = "Seraphim",
  "id_4520" = "do Grito",
  "id_4521" = "do Sopro Mortal",
  "id_4522" = "de Gelo",
  "id_4523" = "Aquarius",
  "id_4524" = "do Espadachim",
  "id_4525" = "da Furtividade",
  "id_4526" = "da Defesa Mágica",
  "id_4527" = "da Defesa Física",
  "id_4528" = "do Espírito",
  "id_4529" = "do Físico",
  "id_4530" = "do Mercador",
  "id_4531" = "das Maldições",
  "id_4532" = "da Corrida",
  "id_4533" = "da Cegueira",
  "id_4534" = "da Bomba D'Água",
  //*****************************************************
  //------------Aplicar em 2015-02-02--------------------
  //*****************************************************
  "id_4539" = "do Arrogante Selado",
  "id_4624" = "do Alto Risco Selado",
  //*****************************************************
  //-----------------EP 14.3-----------------------------
  //*****************************************************
  "id_4559" = "do Inescapável",
  //*****************************************************
  //-----------------Heroes Trail Part II----------------
  //*****************************************************
  "id_4556" = "do Feitiço Incisivo",
  "id_4557" = "dos Feitiços",
  //*****************************************************
  //----------------Aplicar em 2017-07-03----------------
  //*****************************************************
  "id_27120" = "em Raios Fúlgidos",
  "id_27121" = "do Ipiranga",
  "id_27122" = "da Pátria Amada",
  "id_27123" = "do Formoso Céu",
  "id_27124" = "dos Filhos deste Solo",
  "id_27125" = "do Brado Retumbante",
  "id_27126" = "da Fúria Folclórica",
  //*****************************************************
  //-------------------OLD GLAST HEIM--------------------
  //*****************************************************
  "id_4601" = "do Amaldiçoado",
  "id_4603" = "da Aura Maldita",
  "id_4605" = "da Maldição Física",
  "id_4606" = "da Maldição Arcana",
  //*****************************************************
  //-----------------------EP15--------------------------
  //*****************************************************
  "id_27012" = "da Proteção Mecânica",
  "id_27013" = "das Cápsulas",
  "id_27014" = "da Robustez",
  "id_27015" = "de Reparo",
  "id_27016" = "da Exploração",
  "id_27017" = "da Sucata",
  "id_27018" = "da Oficina",
  "id_27019" = "Industrial",
  "id_27020" = "da Anomalia",
  "id_4697" = "da Sobrecarga",
  "id_4698" = "de Arremesso",
  "id_4699" = "dos Canhões",
  //*****************************************************
  //------------Clock Tower Nightmare Cards--------------
  //*****************************************************
  "id_4625" = "do Vigia",
  "id_4626" = "dos Ecos",
  "id_4627" = "Vibrante",
  "id_4628" = "do Bolor",
  "id_4629" = "da Desolação",
  "id_4630" = "da Desgraça",
  "id_4631" = "Célere",
  "id_4632" = "Voltaico",
  //*****************************************************
  //----------------Sufixo Encantamentos----------------
  //*****************************************************
  //FOR+1~FOR+10
  "id_4700" = "(FOR+1) ",
  "id_4701" = "(FOR+2) ",
  "id_4702" = "(FOR+3) ",
  "id_4703" = "(FOR+4) ",
  "id_4704" = "(FOR+5) ",
  "id_4705" = "(FOR+6) ",
  "id_4706" = "(FOR+7) ",
  "id_4707" = "(FOR+8) ",
  "id_4708" = "(FOR+9) ",
  "id_4709" = "(FOR+10) ",

  //INT+1~INT+10
  "id_4710" = "(INT+1) ",
  "id_4711" = "(INT+2) ",
  "id_4712" = "(INT+3) ",
  "id_4713" = "(INT+4) ",
  "id_4714" = "(INT+5) ",
  "id_4715" = "(INT+6) ",
  "id_4716" = "(INT+7) ",
  "id_4717" = "(INT+8) ",
  "id_4718" = "(INT+9) ",
  "id_4719" = "(INT+10) ",

  //DES+1~DES+10
  "id_4720" = "(DES+1) ",
  "id_4721" = "(DES+2) ",
  "id_4722" = "(DES+3) ",
  "id_4723" = "(DES+4) ",
  "id_4724" = "(DES+5) ",
  "id_4725" = "(DES+6) ",
  "id_4726" = "(DES+7) ",
  "id_4727" = "(DES+8) ",
  "id_4728" = "(DES+9) ",
  "id_4729" = "(DES+10) ",

  //AGI+1~AGI+10
  "id_4730" = "(AGI+1) ",
  "id_4731" = "(AGI+2) ",
  "id_4732" = "(AGI+3) ",
  "id_4733" = "(AGI+4) ",
  "id_4734" = "(AGI+5) ",
  "id_4735" = "(AGI+6) ",
  "id_4736" = "(AGI+7) ",
  "id_4737" = "(AGI+8) ",
  "id_4738" = "(AGI+9) ",
  "id_4739" = "(AGI+10) ",

  //VIT+1~VIT+10
  "id_4740" = "(VIT+1) ",
  "id_4741" = "(VIT+2) ",
  "id_4742" = "(VIT+3) ",
  "id_4743" = "(VIT+4) ",
  "id_4744" = "(VIT+5) ",
  "id_4745" = "(VIT+6) ",
  "id_4746" = "(VIT+7) ",
  "id_4747" = "(VIT+8) ",
  "id_4748" = "(VIT+9) ",
  "id_4749" = "(VIT+10) ",

  //SOR+1~SOR+10
  "id_4750" = "(SOR+1) ",
  "id_4751" = "(SOR+2) ",
  "id_4752" = "(SOR+3) ",
  "id_4753" = "(SOR+4) ",
  "id_4754" = "(SOR+5) ",
  "id_4755" = "(SOR+6) ",
  "id_4756" = "(SOR+7) ",
  "id_4757" = "(SOR+8) ",
  "id_4758" = "(SOR+9) ",
  "id_4759" = "(SOR+10) ",

  //CRI
  "id_4764" = "(CRI+5) ",
  "id_4765" = "(CRI+7) ",

  //DEF
  "id_4791" = "(DEF+3) ",
  "id_4792" = "(DEF+6) ",
  "id_4793" = "(DEF+9) ",
  "id_4794" = "(DEF+12) ",

  //DEFM
  "id_4786" = "(DEFM+2) ",
  "id_4787" = "(DEFM+4) ",
  "id_4788" = "(DEFM+6) ",
  "id_4789" = "(DEFM+8) ",
  "id_4790" = "(DEFM+10) ",

  //ATQ
  "id_4819" = "(ATQ+1%) ",
  "id_4766" = "(ATQ+2%) ",
  "id_4767" = "(ATQ+3%) ",

  //ATQM
  "id_4760" = "(ATQM+1%) ",
  "id_4761" = "(ATQM+2%) ",
  "id_4806" = "(ATQM+3%) ",

  //ESQV
  "id_4859" = "(ESQV+1) ",
  "id_4860" = "(ESQV+3) ",
  "id_4762" = "(ESQV+6) ",
  "id_4763" = "(ESQV+12) ",

  //HP
  "id_4795" = "(HP+100) ",
  "id_4796" = "(HP+200) ",
  "id_4797" = "(HP+300) ",
  "id_4798" = "(HP+400) ",
  "id_4799" = "(HP+500) ",

  //HP%
  "id_4861" = "(HP+1%) ",
  "id_4862" = "(HP+2%) ",
  "id_4867" = "(HP+3%) ",
  "id_4868" = "(HP+4%) ",
  "id_4900" = "(HP+5%) ",

  //SP
  "id_4870" = "(SP+25) ",
  "id_4800" = "(SP+50) ",
  "id_4871" = "(SP+75) ",
  "id_4801" = "(SP+100) ",
  "id_4802" = "(SP+150) ",

  //Super Atributos
  "id_4853" = "(S.FOR) ",
  "id_4854" = "(S.AGI) ",
  "id_4855" = "(S.VIT) ",
  "id_4856" = "(S.INT) ",
  "id_4857" = "(S.DES) ",
  "id_4858" = "(S.SOR) ",

  //Encantos de GH
  "id_4875" = "(Delírio) ",
  "id_4876" = "(Epifania) ",
  "id_4877" = "(Ânimo) ",
  "id_4878" = "(Histeria) ",
  "id_4879" = "(Instinto) ",
  "id_4880" = "(Euforia) ",

  //Redução Asas de Arcanjo
  "id_4848" = "(RN5) ",
  "id_4849" = "(RH5) ",

  //Espírito do Lutador
  "id_4811" = "(EL 1) ",
  "id_4810" = "(EL 2) ",
  "id_4809" = "(EL 3) ",
  "id_4808" = "(EL 4) ",
  "id_4820" = "(EL 5) ",
  "id_4821" = "(EL 6) ",
  "id_4822" = "(EL 7) ",
  "id_4823" = "(EL 8) ",
  "id_4824" = "(EL 9) ",
  "id_4825" = "(EL 10) ",

  //Pedra do Encantamento
  "id_4815" = "(PE 1) ",
  "id_4814" = "(PE 2) ",
  "id_4813" = "(PE 3) ",
  "id_4812" = "(PE 4) ",
  "id_4826" = "(PE 5) ",
  "id_4827" = "(PE 6) ",
  "id_4828" = "(PE 7) ",
  "id_4829" = "(PE 8) ",
  "id_4830" = "(PE 9) ",
  "id_4831" = "(PE 10) ",

  //Mira Apurada
  "id_4832" = "(MA 1) ",
  "id_4833" = "(MA 2) ",
  "id_4834" = "(MA 3) ",
  "id_4835" = "(MA 4) ",
  "id_4836" = "(MA 5) ",
  "id_4837" = "(MA 6) ",
  "id_4838" = "(MA 7) ",
  "id_4839" = "(MA 8) ",
  "id_4840" = "(MA 9) ",
  "id_4841" = "(MA 10) ",

  //Pedra de Crítico
  "id_4818" = "(PC 1) ",
  "id_4817" = "(PC 2) ",
  "id_4816" = "(PC 3) ",
  "id_4843" = "(PC 4) ",
  "id_4844" = "(PC 5) ",

  //Fatal
  "id_4863" = "(F 1) ",
  "id_4864" = "(F 2) ",
  "id_4865" = "(F 3) ",
  "id_4866" = "(F 4) ",

  //AB Mora
  "id_4803" = "(Cura 1) ",
  "id_4804" = "(Catol 1) ",

  //Arcebispo
  "id_4805" = "(AB 1) ",
  "id_4850" = "(AB 2) ",
  "id_4851" = "(AB 3) ",
  "id_4852" = "(AB 4) ",

  //Anti Atraso
  "id_4869" = "(AA 1) ",
  "id_4872" = "(AA 2) ",
  "id_4873" = "(AA 3) ",
  "id_4881" = "(AA 4) ",

  //V.Atq
  "id_4807" = "(V.Atq+1) ",
  "id_4842" = "(V.Atq+2) ",

  //Essências
  "id_4908" = "(E.FOR1) ",
  "id_4909" = "(E.FOR2) ",
  "id_4910" = "(E.FOR3) ",
  "id_4911" = "(E.INT1) ",
  "id_4912" = "(E.INT2) ",
  "id_4913" = "(E.INT3) ",
  "id_4914" = "(E.AGI1) ",
  "id_4915" = "(E.AGI2) ",
  "id_4916" = "(E.AGI3) ",
  "id_4917" = "(E.VIT1) ",
  "id_4918" = "(E.VIT2) ",
  "id_4919" = "(E.VIT3) ",
  "id_4920" = "(E.DES1) ",
  "id_4921" = "(E.DES2) ",
  "id_4922" = "(E.DES3) ",
  "id_4923" = "(E.SOR1) ",
  "id_4924" = "(E.SOR2) ",
  "id_4925" = "(E.SOR3) ",

  //Anel do Herói
  "id_4882" = "(ATQ +1%) ",
  "id_4894" = "(ATQ +4%) ",
  "id_4895" = "(ATQ +5%) ",
  "id_4904" = "(ATQ +6%) ",
  "id_4905" = "(ATQ +7%) ",
  "id_4883" = "(ATQM 1) ",
  "id_4896" = "(ATQM 2) ",
  "id_4897" = "(ATQM 3) ",
  "id_4898" = "(ATQM 4) ",
  "id_4899" = "(ATQM 5) ",
  "id_4906" = "(ATQM 6) ",
  "id_4907" = "(ATQM 7) ",

  //Pedra de Feitiço
  "id_4885" = "(PF 1) ",
  "id_4886" = "(PF 2) ",
  "id_4887" = "(PF 3) ",
  "id_4888" = "(PF 4) ",
  "id_4889" = "(PF 5) ",

  //Neutralidade
  "id_4933" = "(Neut 1) ",
  "id_4934" = "(Neut 2) ",
  "id_4935" = "(Neut 3) ",

  //E.X.C.
  "id_4970" = "(E-Fogo) ",
  "id_4971" = "(E-Água) ",
  "id_4972" = "(E-Terra) ",
  "id_4973" = "(E-Vento) ",
  "id_4974" = "(R-Fogo) ",
  "id_4975" = "(R-Água) ",
  "id_4976" = "(R-Terra) ",
  "id_4977" = "(R-Vento) ",
  "id_4978" = "(C-Corpo) ",
  "id_4979" = "(C-Alma) ",
  "id_4980" = "(C-Físico) ",
  "id_4981" = "(C-Mental) ",
  "id_4982" = "(A-FOR) ",
  "id_4983" = "(A-INT) ",
  "id_4984" = "(A-DEF) ",
  "id_4985" = "(A-ESQV) ",
  "id_4986" = "(A-ATQ) ",
  "id_4987" = "(A-ATQM) ",
  "id_4988" = "(A-HP) ",
  "id_4989" = "(A-SP) ",
  "id_4990" = "(I-Gelo) ",
  "id_4991" = "(A-VdA) ",

  //*****************************************************
  //---ECLAGE / PORT MALAYA / BIO 4 / PORTAL DO ABISMO---
  //*****************************************************
  "id_4560" = "da Poesia",
  "id_4561" = "da Proteção Terrena",
  "id_4562" = "da Penetração",
  "id_4563" = "da Corrosão",
  "id_4564" = "da Incapacitação",
  "id_4565" = "da Cruz Suprema",
  "id_4566" = "da Boa Ventura",
  "id_4567" = "da Assombração Poeta",
  "id_4568" = "da Aparição Erudita",
  "id_4569" = "do Espírito Lutador",
  "id_4570" = "do Espectro da Gênese",
  "id_4571" = "da Alma da Desordem",
  "id_4572" = "do Fantasma Virtuoso",
  "id_4573" = "da Visão Musical",
  "id_4574" = "do Mestre das Lâminas",
  "id_4575" = "do Corte Veloz",
  "id_4576" = "Caça-Fantasmas",
  "id_4577" = "Afugenta Fantasmas",
  "id_4578" = "do Furor",
  "id_4579" = "da Devastação",
  "id_4580" = "da Perversão",
  "id_4581" = "da Higiene",
  "id_4582" = "da Energia Vital",
  "id_4583" = "da Vinha Venenosa",
  "id_4584" = "da Absorção Espiritual",
  "id_4585" = "do Atormentado",
  "id_4586" = "do Zéfiro",
  "id_4587" = "do Corte Profundo",
  "id_4588" = "da Potência",
  "id_4589" = "da Fartura",
  "id_4590" = "da Letargia",
  "id_4591" = "da Ruptura",
  "id_4592" = "Petrificante",
  "id_4593" = "do Franco-atirador",
  "id_4594" = "do Golpe de Sorte",
  "id_4595" = "do Ataque Relâmpago",
  "id_4596" = "da Perspicácia",
  "id_4597" = "Borbulhante",
  "id_4598" = "Espectral",
  "id_4599" = "Ardente",
  "id_4600" = "Brotante",
  "id_4607" = "do Bibliotecário",

  //*****************************************************
  //------------Horror Toy Factory Cards---------
  //*****************************************************
  "id_31016" = "da Persistência",
  "id_31017" = "da Petulância",
  "id_31018" = "da Maldição à Distância",
  "id_31019" = "da Retaliação Maldita",
  "id_31020" = "do Sacrifício Maldito",
  "id_31021" = "da Resiliência",
  "id_31022" = "do Desespero",
  "id_31023" = "dos Ferimentos Mortais",

  //*****************************************************
  //-------------Moscovia Cards-------------
  //*****************************************************
  "id_27157" = "do Ecossistema",
  "id_27158" = "Anti Vento",
  "id_27159" = "do Exterminador de Demônios",
  "id_27160" = "do Intocável",
  "id_27161" = "das Forças da Natureza",
  "id_27162" = "do Pacto de Mana",
  //*****************************************************
  //-------------GLAST HEIM SOMBRIA CARDS----------------
  //*****************************************************
  "id_4602" = "do Assombrado",
  "id_4604" = "da Aura Sombria",
  "id_4608" = "do Cavaleiro",
  "id_4609" = "da Cavaleira",
  //*****************************************************
  //-------------PYRAMID NIGHT MARE CARDS----------------
  //*****************************************************
  "id_4652" = "Abismal",
  "id_4653" = "do Predador",
  "id_4654" = "do Caça-Aberrações",
  "id_4655" = "do Caça-Zumbis",
  "id_4656" = "dos Sussurros",
  "id_4657" = "da Maldição Ancestral",
  "id_4658" = "do Arcanista",
  //*****************************************************
  //-------------HOROES TRAIL PART 1 CARDS---------------
  //*****************************************************
  "id_27163" = "do Invertebrado",
  "id_27164" = "da Rainha",
  "id_27165" = "Parasita",
  "id_27166" = "Termais",
  "id_27167" = "Glacial",
  "id_27168" = "do Pai",
  "id_27169" = "da Infantaria",
  //*****************************************************
  //------------QUEST DAS FADAS 02-07-2019---------------
  //*****************************************************
  "id_29135" = "(FA1) ",
  "id_29136" = "(FA2) ",
  "id_29137" = "(FA3) ",
  "id_29138" = "(FA4) ",
  //*****************************************************
  //-------------------Episode 16.1----------------------
  //*****************************************************
  "id_31024" = "Impactante",
  "id_31025" = "Intoxicante",
  "id_31026" = "de Autoconjuração",
  "id_4446" = "Super Fatal",
  "id_4633" = "Super Ciclonal",
  "id_4634" = "Super Letal",
  "id_4635" = "da Fortaleza",
  "id_4636" = "da Imunidade",
  "id_4637" = "da Regeneração",
  "id_4638" = "da Agressividade",
  "id_4639" = "de Ataque",
  "id_4640" = "de Magia",
  "id_4641" = "da Coleta de Mana",
  "id_4994" = "(RP 1) ",
  "id_4995" = "(RP 2) ",
  "id_4996" = "(RP 3) ",
  "id_4997" = "(RL 1) ",
  "id_4998" = "(RL 2) ",
  "id_4999" = "(RL 3) ",
  "id_29000" = "(RM 1) ",
  "id_29001" = "(RM 2) ",
  "id_29002" = "(RM 3) ",
  "id_29003" = "(RV 1) ",
  "id_29004" = "(RV 2) ",
  "id_29005" = "(RV 3) ",
  "id_29006" = "(RA 1) ",
  "id_29007" = "(RA 2) ",
  "id_29008" = "(RA 3) ",
  "id_29009" = "(RS 1) ",
  "id_29010" = "(RS 2) ",
  "id_29011" = "(RS 3) ",
  //*****************************************************
  //-------------------DORAM CARDS----------------------
  //*****************************************************
  "id_4659" = "do Ovo Frito",
  "id_4660" = "da Rigidez",
  "id_4661" = "do Escamoso",
  "id_4662" = "do Omelete",
  "id_4663" = "do Farfalhar",
  "id_4664" = "da Grama",
  "id_4665" = "do Ferrão",
  "id_4666" = "da Doçura",
  "id_4667" = "do Faro",
  "id_4668" = "da Água Doce",
  "id_4669" = "da Ventania",
  "id_4670" = "do Fogaréu",
  //*****************************************************
  //--------------Sufixo Novos Encantamentos-------------
  //*****************************************************
  "id_4928" = "(SP + 10) ",
  "id_4929" = "(SP + 1%) ",
  "id_4992" = "(C.HP 1) ",
  "id_4993" = "(C.SP 1) ",
  "id_29047" = "(M 1) ",
  "id_29359" = "(M 2) ",
  "id_29360" = "(M 3) ",
  "id_29361" = "(M 4) ",
  //*****************************************************
  //-----------Sufixo Ordem dos Exploradores-------------
  //*****************************************************
  "id_29046" = "(Ganância) ",
  "id_29052" = "(Graça Divina) ",
  "id_29147" = "(Ressuscitar) ",
  "id_4893" = "(DEF+15) ",
  "id_4902" = "(DEF+18) ",
  "id_4903" = "(DEF+21) ",
  "id_4890" = "(DEFM+1) ",
  "id_4891" = "(DEFM+3) ",
  "id_4892" = "(DEFM+5) ",
  "id_4901" = "(DEFM+7) ",
  "id_4545" = "do Bebê",
  //*****************************************************
  //-----------Sufixo Ordem dos Exploradores-------------
  //*****************************************************
  "id_29445" = "(PA 1) ",
  "id_29446" = "(PA 2) ",
  "id_29447" = "(PA 3) ",
  "id_29448" = "(PA 4) ",
  "id_29449" = "(PA 5) ",
  "id_29139" = "(FA 5) ",
  //*****************************************************
  //------------Sufixo Festival de Brasilis--------------
  //*****************************************************
  "id_4846" = "(AC) ",
  //*****************************************************
  //-------------BIO 5 and Heroes Trail Pt 3-------------
  //*****************************************************
  "id_4671" = "da Feiticeira",
  "id_4672" = "do Shura",
  "id_4673" = "do Trovador",
  "id_4674" = "do Sicário",
  "id_4675" = "da Arcebispa",
  "id_4676" = "da Sentinela",
  "id_4677" = "do Mecânico",
  "id_4678" = "da Arcana",
  "id_4679" = "do Cavaleiro Rúnico",
  "id_4680" = "do Guardião Real",
  "id_4681" = "do Bioquímico",
  "id_4682" = "da Renegada",
  "id_4683" = "da Musa",
  "id_4684" = "das Lâminas",
  "id_4685" = "de Adoramus",
  "id_4686" = "do Meteoro",
  "id_4687" = "da Bomba",
  "id_4688" = "do Furacão",
  "id_4689" = "da Ignição",
  "id_4690" = "Inferi",
  "id_4691" = "do Tornado",
  "id_4692" = "dos Aesir",
  "id_4693" = "dos Espíritos",
  "id_4694" = "da Cópia",
  "id_4695" = "do Temporal",
  "id_4696" = "da Ressonância",
  "id_4610" = "Invencível",
  "id_27181" = "da Invasão",
  "id_27182" = "do Capitão",
  "id_27183" = "dos Gigantes",
  "id_4939" = "(CRIT +2) ",
  "id_4940" = "(CRIT +4) ",
  "id_4941" = "(CRIT +6) ",
  "id_4942" = "(EP +3) ",
  "id_4943" = "(EP +4) ",
  "id_4944" = "(EP +5) ",
  "id_4945" = "(E 1) ",
  "id_4946" = "(E 2) ",
  "id_4947" = "(E 3) ",
  "id_4948" = "(AH 1) ",
  "id_4949" = "(AH 2) ",
  "id_4950" = "(AH 3) ",
  "id_29061" = "(IP 1) ",
  "id_29062" = "(IP 2) ",
  "id_29063" = "(IP 3) ",
  "id_29064" = "(IP 4) ",
  "id_29065" = "(IP 5) ",
  "id_29066" = "(IP 6) ",
  "id_29067" = "(IP 7) ",
  "id_29068" = "(IP 8) ",
  "id_29069" = "(IP 9) ",
  "id_29070" = "(IP 10) ",
  "id_29071" = "(IV 1) ",
  "id_29072" = "(IV 2) ",
  "id_29073" = "(IV 3) ",
  "id_29074" = "(IV 4) ",
  "id_29075" = "(IV 5) ",
  "id_29076" = "(IV 6) ",
  "id_29077" = "(IV 7) ",
  "id_29078" = "(IV 8) ",
  "id_29079" = "(IV 9) ",
  "id_29080" = "(IV 10) ",
  "id_29081" = "(IT 1) ",
  "id_29082" = "(IT 2) ",
  "id_29083" = "(IT 3) ",
  "id_29084" = "(IT 4) ",
  "id_29085" = "(IT 5) ",
  "id_29086" = "(IT 6) ",
  "id_29087" = "(IT 7) ",
  "id_29088" = "(IT 8) ",
  "id_29089" = "(IT 9) ",
  "id_29090" = "(IT 10) ",
  "id_29091" = "(IM 1) ",
  "id_29092" = "(IM 2) ",
  "id_29093" = "(IM 3) ",
  "id_29094" = "(IM 4) ",
  "id_29095" = "(IM 5) ",
  "id_29096" = "(IM 6) ",
  "id_29097" = "(IM 7) ",
  "id_29098" = "(IM 8) ",
  "id_29099" = "(IM 9) ",
  "id_29100" = "(IM 10) ",
  "id_29101" = "(IR 1) ",
  "id_29102" = "(IR 2) ",
  "id_29103" = "(IR 3) ",
  "id_29104" = "(IR 4) ",
  "id_29105" = "(IR 5) ",
  "id_29106" = "(IR 6) ",
  "id_29107" = "(IR 7) ",
  "id_29108" = "(IR 8) ",
  "id_29109" = "(IR 9) ",
  "id_29110" = "(IR 10) ",
  "id_29111" = "(IA 1) ",
  "id_29112" = "(IA 2) ",
  "id_29113" = "(IA 3) ",
  "id_29114" = "(IA 4) ",
  "id_29115" = "(IA 5) ",
  "id_29116" = "(IA 6) ",
  "id_29117" = "(IA 7) ",
  "id_29118" = "(IA 8) ",
  "id_29119" = "(IA 9) ",
  "id_29120" = "(IA 10) ",
  //*****************************************************
  //-----------------------EP 14.3-----------------------
  //*****************************************************
  "id_27321" = "de Satã",
  "id_27322" = "do Apóstolo",
  "id_27323" = "da Apóstola",
  "id_27324" = "do Frio",
  "id_27325" = "do Calor",
  "id_27326" = "da Destruição",
  "id_27327" = "do Ceifador",
  "id_27328" = "dos Caídos",
  "id_27026" = "Quente",
  "id_27027" = "Incandescente",
  "id_27028" = "da Queimada",
  "id_27029" = "Corta-Fogo",
  "id_27030" = "do Incêndio",
  "id_27170" = "do Caubói",
  "id_27171" = "do Vaqueiro",
  "id_27172" = "da Peixeira",
  "id_27173" = "do Velho-Oeste",
  "id_27174" = "do Justiceiro",
  "id_27175" = "do Facão",
  "id_27176" = "da Sujeira",
  "id_27177" = "da Nojeira",
  "id_27178" = "da Guarda Irreal",
  "id_27179" = "Acme",
  "id_27180" = "do Desarme",
  "id_27249" = "de Alphard",
  "id_27250" = "do Sirius",
  "id_27251" = "do Bandido",
  "id_27252" = "do Valentão",
  "id_27253" = "da Ladra",
  "id_27254" = "da Mirzam",
  "id_27255" = "da Muliphen",
  "id_27256" = "da Wezen",
  "id_27257" = "do Wei",
  "id_27258" = "do Sargas",
  "id_27259" = "da Shaula",
  "id_27260" = "da Ahdara",
  "id_27261" = "do Procyon",
  "id_27262" = "de Atria",
  "id_27263" = "de Kanavion",
  "id_27264" = "das Trevas",
  "id_27265" = "Ocultista",
  "id_27184" = "das Mágoas",
  //*****************************************************
  //-----------------------EP 16.2-----------------------
  //*****************************************************
  "id_27147" = "da Intensividade",
  "id_27148" = "Padrão",
  "id_27149" = "do Disparo",
  "id_27150" = "Quimera",
  "id_27151" = "da Malícia",
  "id_27152" = "da Indução",
  //*****************************************************
  //-------------------ROYAL HUNTING---------------------
  //*****************************************************
  "id_27196" = "de Nihil",
  "id_27197" = "de Agnes",
  "id_27198" = "de Jurgen",
  "id_27199" = "das Gêmeas",
  "id_27331" = "Heine",
  "id_27333" = "Nerius",
  "id_27390" = "Lugenburg",
  "id_27392" = "RCharTyped",
  "id_27394" = "da Cortesia",
  "id_27396" = "Wigner",
  "id_27200" = "Autografado por Nihil",
  "id_27201" = "Autografado por Agnes",
  "id_27202" = "Autografado por Jurgen",
  "id_27203" = "Autografado por Spica",
  "id_27330" = "Autografado por Kronecker",
  "id_27332" = "Autografado por Skia",
  "id_27393" = "Autografado por Katrin",
  "id_27395" = "Autografado por Isaac",
  "id_27389" = "Autografado por Wolf",
  "id_27391" = "Autografado por Poe",
  "id_300127" = "com autógrafo de Wolf",
  "id_300128" = "de Lugenburg",
  "id_300129" = "com autógrafo de Poe",
  "id_300130" = "de Poe",
  "id_4488" = "da Perdição Selada",
  //*****************************************************
  //-------------------BRASILIS EVENT--------------------
  //*****************************************************
  "id_27413" = "(PX) ",
  "id_27414" = "(PO) ",
  "id_27415" = "(PI) ",
  "id_27416" = "(PU) ",
  //*****************************************************
  //------------------EDDA ARUNEFELTZ--------------------
  //*****************************************************
  "id_29121" = "(DJ 1) ",
  "id_29122" = "(DJ 2) ",
  "id_29123" = "(DA 1) ",
  "id_29124" = "(DA 2) ",
  "id_29125" = "(DV 1) ",
  "id_29126" = "(DV 2) ",
  //*****************************************************
  //---------------Update em 2022-05-17------------------
  //*****************************************************
  "id_27217" = "da Incapacitação Selada",
  //*****************************************************
  //--------------------ILUSÃO DA LUA--------------------
  //*****************************************************
  "id_27081" = "da Lua",
  "id_27082" = "de Nove Caudas",
  "id_27083" = "do Crescimento",
  "id_27084" = "do Ressentimento",
  "id_27085" = "da Delicadeza",
  "id_27086" = "do Ciclone",
  "id_27087" = "da Verdade",
  "id_27088" = "do Aventureiro",
  "id_29209" = "(C.SP 2) ",
  "id_29013" = "(C.HP 3) ",
  //*****************************************************
  //---------------Update em 2022-07-05------------------
  //*****************************************************
  "id_27221" = "Caça-Fantasmas Selada",
  "id_27225" = "do Vigia Selada",
  //*****************************************************
  //---------------Update em 2022-08-30------------------
  //*****************************************************
  "id_27215" = "da Penetração Selada",
  //*****************************************************
  //-----------Illusion of Turtle Island-----------------
  //*****************************************************
  "id_27114" = "da Firmeza",
  "id_27115" = "da Sensibilidade",
  "id_27116" = "da Dualidade",
  "id_27117" = "da Letalidade",
  "id_27118" = "das Lanças",
  "id_27119" = "da Tartaruga",
  //*****************************************************
  //---------------Illusion of Vampire-------------------
  //*****************************************************
  "id_27101" = "dos Sonhos",
  "id_27102" = "Anti-inflamável",
  "id_27103" = "Zumbi",
  "id_27104" = "da Compaixão",
  "id_27105" = "da Promessa",
}
