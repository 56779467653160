import { Theme } from "@mui/material";

export const dividerStyle: Partial<Theme["components"]> = {
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "#E6E8EC",
      },
    },
  },
};
