import { Theme } from "@mui/material";

export const cssBaselineStyle: Partial<Theme["components"]> = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: "white",
        fontFamily: "DM Sans",
      },
    },
  },
};
