import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import instance from "@/config/instance";
import { generatePath } from "react-router-dom";
import { ItemListType } from "@/types/ItemType";
import qs from "qs";

export const STORAGE_API = "/storage/:id";
export const useGetStorage = (id?: string, params?: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  const getStorageItems = (): Promise<ItemListType> =>
    instance.get(
      `${generatePath(STORAGE_API, { id: id! })}?${qs.stringify({
        filters: params,
      })}`
    );

  const { data, ...rest } = useQuery<ItemListType, any, ItemListType, any>({
    queryKey: [STORAGE_API, id, params],
    queryFn: getStorageItems,
    structuralSharing: false,
    enabled: !!id,
  });

  return useMemo(() => {
    const storage = data ?? { list: [], count: 0 };
    const refresh = () => {
      queryClient.invalidateQueries({ queryKey: [STORAGE_API, id, params] });
    };

    return {
      storage,
      refresh,
      ...rest,
    };
  }, [data, id, params, queryClient, rest]);
};
