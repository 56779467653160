import { RouterProvider } from "react-router-dom";
import { router } from "@/config/router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CookiesProvider } from "react-cookie";
import env from "@/config/env";
import { queryClient } from "@/config/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";

import { ThemeProvider } from "@/providers/ThemeProvider";
import { SnackbarProvider } from "@/providers/SnackbarProvider";

import "@/config/i18n/index";
import { withErrorHandling } from "./utils/errorHandler";

function App() {
  const RouterProviderWithError = withErrorHandling(() => (
    <RouterProvider router={router} />
  ));

  return (
    <GoogleOAuthProvider clientId={env.authConfig.googleAuthProvider.clientId}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <ThemeProvider>
            <SnackbarProvider>
              <RouterProviderWithError />
            </SnackbarProvider>
          </ThemeProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
