import { CellField } from "@/components/CellField";
import { formatDateLong } from "@/utils/date.utils";
import { formatNumberWithThousandAndDecimalSeparator } from "@/utils/numberFormat.utils";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";

const OUT_COLOR = "#f05454";
const IN_COLOR = "#99dfb0";
const PurchaseHistory = () => {
  return (
    <Stack gap={3}>
      <Typography variant="h5">Histórico de Transações</Typography>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={3} alignItems="center">
            <Box
              width={30}
              height={30}
              borderRadius="50%"
              border="1px solid"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={OUT_COLOR}
            >
              <Box component={FaArrowUpLong} sx={{ fontSize: 12 }} />
            </Box>
            <CellField
              title="+10 Espada Primordial Capital Bi"
              value={formatDateLong(new Date().toDateString())}
            />
          </Stack>

          <CellField title="Compra" value="Cartão de crédito **34" />
          <CellField
            title={`
                R$ ${formatNumberWithThousandAndDecimalSeparator("123124")}
              `}
          />
        </Stack>
        <Box width="100%" my={2}>
          <Divider />
        </Box>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={3} alignItems="center">
            <Box
              width={30}
              height={30}
              borderRadius="50%"
              border="1px solid"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={OUT_COLOR}
            >
              <Box component={FaArrowUpLong} sx={{ fontSize: 12 }} />
            </Box>
            <CellField
              title="+10 Espada Primordial Capital Bi"
              value={formatDateLong(new Date().toDateString())}
            />
          </Stack>
          <CellField title="Compra no mercado" value="Cartão de crédito **34" />
          <CellField
            title={`
                R$ ${formatNumberWithThousandAndDecimalSeparator("123124")}
              `}
          />
        </Stack>
        <Box width="100%" my={2}>
          <Divider />
        </Box>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={3} alignItems="center">
            <Box
              width={30}
              height={30}
              borderRadius="50%"
              border="1px solid"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={IN_COLOR}
            >
              <Box component={FaArrowDownLong} sx={{ fontSize: 12 }} />
            </Box>
            <CellField
              title="+10 Espada Primordial Capital Bi"
              value={formatDateLong(new Date().toDateString())}
            />
          </Stack>
          <CellField title="Compra no mercado" value="Carteira" />
          <CellField
            title={`
                R$ ${formatNumberWithThousandAndDecimalSeparator("123124")}
              `}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(PurchaseHistory);
