import { isEqual } from "lodash";

export function diffObjects<T extends object, U extends object>(
  obj1: T,
  obj2: U
): Partial<T> {
  const differences: Partial<T> = {};

  // Iterar sobre as chaves de obj1
  Object.keys(obj1).forEach((key) => {
    const value1 = obj1[key as keyof T];
    const value2 = obj2[key as keyof U];

    // Se os valores forem diferentes, adicionar ao objeto de diferenças
    if (!isEqual(value1, value2)) {
      differences[key as keyof T] = value1;
    }
  });

  return differences;
}
