export const formatNumberWithThousandSeparator = (
  number: string,
  thousandSeparator: string = "."
): string => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
};

export const formatNumberWithThousandAndDecimalSeparator = (
  number: string,
  decimalSeparator: string = ",",
  thousandSeparator: string = "."
): string => {
  const cleanedValue = number.replace(/\D/g, ""); // Remove tudo que não for dígito
  const integerPart = cleanedValue.slice(0, -2); // Parte inteira sem os dois últimos dígitos
  const decimalPart = cleanedValue.slice(-2); // Últimos dois dígitos como parte decimal

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator
  );

  return `${Math.max(
    0,
    Number(formattedIntegerPart)
  )}${decimalSeparator}${decimalPart}`;
};

export const formatCPF = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, "");
  const part1 = cleanedValue.slice(0, 3);
  const part2 = cleanedValue.slice(3, 6);
  const part3 = cleanedValue.slice(6, 9);
  const part4 = cleanedValue.slice(9, 11);

  if (cleanedValue.length <= 3) return part1;
  if (cleanedValue.length <= 6) return `${part1}.${part2}`;
  if (cleanedValue.length <= 9) return `${part1}.${part2}.${part3}`;
  return `${part1}.${part2}.${part3}-${part4}`;
};

export const formatPhone = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, "");
  const part1 = cleanedValue.slice(0, 2);
  const part2 = cleanedValue.slice(2, 7);
  const part3 = cleanedValue.slice(7, 11);

  if (cleanedValue.length <= 2) return `(${part1}`;
  if (cleanedValue.length <= 7) return `(${part1}) ${part2}`;
  return `(${part1}) ${part2}-${part3}`;
};

const formatZip = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, "");
  const part1 = cleanedValue.slice(0, 5);
  const part2 = cleanedValue.slice(5, 8);

  if (cleanedValue.length <= 5) return part1;
  return `${part1}-${part2}`;
};

const formatCard = (value: string): string => {
  const cleanedValue = value.replace(/\D/g, "");
  const part1 = cleanedValue.slice(0, 3);
  const part2 = cleanedValue.slice(3, 7);
  const part3 = cleanedValue.slice(7, 11);
  const part4 = cleanedValue.slice(11, 15);

  if (cleanedValue.length <= 4) return part1;
  if (cleanedValue.length <= 8) return `${part1} ${part2}`;
  if (cleanedValue.length <= 12) return `${part1} ${part2} ${part3}`;
  return `${part1} ${part2} ${part3} ${part4}`;
};

export const formatNumber = (
  value: string,
  isDecimal: boolean,
  isCPF: boolean,
  isCard: boolean,
  isZip: boolean,
  isPhone: boolean,
  decimalSeparator: string = ",",
  thousandSeparator: string = "."
): string => {
  if (!value) return value;

  if (isCPF) return formatCPF(value);
  if (isPhone) return formatPhone(value);
  if (isZip) return formatZip(value);
  if (isCard) return formatCard(value);

  const cleanedValue = value.replace(/[^\d]/g, "");
  const formattedValue = isDecimal
    ? formatNumberWithThousandAndDecimalSeparator(
        cleanedValue,
        decimalSeparator,
        thousandSeparator
      )
    : formatNumberWithThousandSeparator(cleanedValue, thousandSeparator);

  return formattedValue;
};
