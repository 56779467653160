import { requiredValue } from "@/utils/required.utils";

export const appConfig = {
  target: requiredValue("VITE_TARGET"),
  apiUrl: requiredValue("VITE_API_URL"),
  assetsCDN: requiredValue("VITE_ASSETS_URL"),
};

export const authConfig = {
  googleAuthProvider: {
    clientId: requiredValue("VITE_GOOGLE_CLIENT_ID"),
  },
};

const envVars = {
  appConfig,
  authConfig,
};

export default envVars;
