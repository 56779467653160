import { FC, memo } from "react";
import NewChar from "./components/NewChar";
import CharInfo from "./components/CharInfo";
import { CharData } from "@/api/char";

interface CharViewProps {
  char?: CharData;
}

const CharView: FC<CharViewProps> = ({ char }) => {
  if (!char) {
    return <NewChar />;
  }

  return <CharInfo char={char} />;
};

export default memo(CharView);
